
import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "../CustomButton/CustomButton";
import exclamationErrorIcon from "../../img/exclamationErrorIcon.svg";
import { TRY_AGAIN } from "../../consts/constants";
import { styles } from "./ErrorComponentStyles";

const ErrorComponent = ({
  btnText,
  closeModalHandler,
  customContainerStyles,
  errorHeading,
  errorText,
  omitCloseButton,
  onRetry,
  showLoader
}) => {
  
  

  return (
    <Box sx={{ ...styles.container, ...customContainerStyles }}>
      <Box sx={styles.topSection}>
      {omitCloseButton ? null : (
        <Box sx={styles.buttonContainer}>
          <Box onClick={closeModalHandler} sx={styles.closeButton}>
            <ClearIcon sx={styles.closeIcon} />
          </Box>
        </Box>
      )}
        <Typography
          component="img"
          src={exclamationErrorIcon}
          sx={styles.errorIcon}
        />
        <Box sx={styles.headingAndSubHeadingContainer}>
          <Typography sx={styles.errorHeading}>{errorHeading}</Typography>
          {!!errorText && (
            <Typography sx={styles.errorSubHeading}>{errorText}</Typography>
          )}
        </Box>
      </Box>
      {!!onRetry && (
        <Box sx={styles.btnContainer}>
          <CustomButton  showLoader ={showLoader}classes={styles.buttonStyle} onClick={onRetry}>
            {btnText || TRY_AGAIN}
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};
ErrorComponent.defaultProps = {
  btnText: "",
  errorHeading: "",
  errorText: "",
  showLoader:false,
};
ErrorComponent.propTypes = {
  btnText: PropTypes.string,
  customContainerStyles: PropTypes.object,
  errorHeading: PropTypes.string,
  errorText: PropTypes.string,
  onRetry: PropTypes.func,
  showLoader:PropTypes.bool,
};

export default ErrorComponent;
