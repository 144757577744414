import React from "react";
import { Box } from "@mui/material";

import { commonStyles } from "../assests/commonStyles";
import { styles } from "./BaseStyles";

const Base = ({ children, customStyles }) => {
  return (
    <Box
      sx={{
        ...commonStyles.pageContainer,
        ...commonStyles.pageWithGreyBg,
        ...styles.container,
        ...customStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default Base;
