import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

import { styles } from "./CustomButtonStyles";

function CustomButton({
  children,
  classes,
  dangerButton,
  endIcon,
  isDisabled,
  onClick,
  showLoader,
  startIcon,
  type,
  component,
  id,
}) {
  return (
    <Button
      type={type}
      onClick={onClick}
      sx={{
        ...styles.buttonStyles,
        ...classes,
        ...(dangerButton ? styles.dangerButton : {}),
        ...(isDisabled || showLoader ? styles.disabledButton : {}),
      }}
      disabled={isDisabled || showLoader}
      endIcon={endIcon}
      startIcon={startIcon}
      component={component}
      {...(id ? { form: id } : {})}
    >
      {children}
      {showLoader && (
        <CircularProgress size={15} thickness={8} sx={styles.btnLoaderStyles} />
      )}
    </Button>
  );
}

CustomButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  endIcon: PropTypes.node,
  showLoader: PropTypes.bool,
  dangerButton: PropTypes.bool,
  component:PropTypes.string
};

export default CustomButton;
