import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

import { styles } from "./PageInput.style";

const PageInput = ({ totalpage, currentPage, handlePageChange }) => {
  const pagesArray = Array.from({ length: totalpage }, (_, index) => index + 1);
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      disableClearable={true}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={currentPage}
      onChange={(event, newValue) => {
        if (newValue !== null && newValue <= totalpage) {
          handlePageChange(newValue);
        }
      }}
      sx={styles.autoCompleteContainer}
      options={pagesArray}
      getOptionLabel={(option) => option.toString()}
      renderOption={(props, option) => (
        <li style={styles.listPages} onClick={() => {}} {...props}>
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="" sx={styles.textField} />
      )}
    />
  );
};
export default PageInput;
