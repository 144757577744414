import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal} from "@mui/material";

import CustomMultiCarousel from "../CustomMultiCarousel/CustomMultiCarousel";
import ZoomableImage from "../ZoomableImage/ZoomableImage";
import { styles } from "./UtilityModalStyles";
import VideoPlayerHsl from "../VideoPlayerHsl/VideoPlayerHsl";

export default function UtilityModal({
  openModal,
  setCloseModal,
  photos,
  selectedPhotoIndex,
  isSinglePicture,
  singleImageSource,
  video,
  setSelectedVideo
}) {
  const [open, setOpen] = useState(openModal);

  const handleClose = () => {
    setOpen(false);
   setSelectedVideo && setSelectedVideo(null);
    setCloseModal(false);
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, true);
    return () => {
      window.removeEventListener("keyup", handleKeyUp, true);
    };

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        handleClose();
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
    e.stopPropagation();
  };
  return (
    <>
      {open && (
        <Modal
          sx={styles.modal}
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEscapeKeyDown={true}
        >
          <Box sx={styles.modalBox}>
            <IconButton onClick={handleClose} sx={styles.closeIconButton}>
              <CloseIcon sx={styles.closeButton} />
            </IconButton>
            {isSinglePicture ? (
              <ZoomableImage
                src={singleImageSource}
                alt="image"
                includeZoomControls
              />
            ) : video ? (
              <VideoPlayerHsl
                customVideoStyles={styles.videoStyle}
                src={video}
              />
            ) : (
              <CustomMultiCarousel
                photos={photos}
                size="large"
                preventOnImageClick
                defaultSelectedPhotoPosition={selectedPhotoIndex}
                isZoomable
              />
            )}
          </Box>
        </Modal>
      )}
    </>
  );
}

UtilityModal.propTypes = {
  openModal: PropTypes.bool,
  setCloseModal: PropTypes.func,
  photos: PropTypes.array,
  selectedPhotoIndex: PropTypes.number,
  isSinglePicture: PropTypes.bool,
  singleImageSource: PropTypes.string,
};
