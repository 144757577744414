import React from "react";
import { Box } from "@mui/material";

import { styles } from "./IntroScreenStyles";

const IntroScreen = ({
  backgroundImage,
  children,
  containerCustomStyles,
  imageStyles,
}) => {
  return (
    <Box
      sx={{
        ...styles.container,
        ...containerCustomStyles,
        backgroundImage: `url(${backgroundImage})`,
        ...styles.backgroundImage,
        ...imageStyles,
      }}
    >
      {children}
    </Box>
  );
};

export default IntroScreen;
