export const styles={
    container:{
        position:"relative"
    },
    videoPlayer:{
        height: "36px",
        width: "36px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        cursor:"pointer"
    }
  
}