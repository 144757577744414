import React from "react";
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, backgroundColor, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor, ...props }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor || theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    ...(props.arrow && { paddingBottom: "6px" }),
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: backgroundColor || theme.palette.common.white,
    },
  },
}));

export default LightTooltip;
