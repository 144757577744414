import styles from './ViewUserDetailsStyle'
export const columns=[
    {
        label:"FirstName*",
        name:"first_name",
        component:"TextField",
        type:"text",
        required:true,
        isTextTransform:true
    },
    {
        label:"LastName*",
        name:"last_name",
        component:"TextField",
        type:"text",
        required:true,
        isTextTransform:true
    },
    {
        label:"Email*",
        name:"email",
        component:"TextField",
        type:"email",
        required:true
    },
    {
        label:"Password*",
        name:"password",
        component:"TextField",
        type:"password",
        required:true
    },
    {
        label:"Phone*",
        name:"phone_number",
        component:"TextField",
        type:"number",
        required:true
    },
]

export const checkBoxGroup = [
 
    {
      name: "account",
      label: "Account",
      component: "CheckBox",
      noLabel: true,
    },
    {
      name: "user",
      label: "User",
      component: "CheckBox",
      noLabel: true,
    },
    {
      name: "deleteUser",
      label: "Delete User",
      component: "CheckBox",
      noLabel: true,
      customLabelStyles: styles.orangeCheckBoxLabel,
    },
    {
      name: "deleteSaaS",
      label: "Delete Saas",
      component: "CheckBox",
      noLabel: true,
      customLabelStyles: styles.orangeCheckBoxLabel,
    },
    {
      name: "categories",
      label: "Categories",
      component: "CheckBox",
      noLabel: true,
    },
    {
      name: "deleteAccount",
      label: "Delete Account",
      component: "CheckBox",
      noLabel: true,
      customLabelStyles: styles.orangeCheckBoxLabel,
    },
    {
      name: "saasAdmin",
      label: "Saas",
      component: "CheckBox",
      noLabel: true,
    },
  ];
  
  export const formFields = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
    saasAdmin: false,
    deleteSaaS: false,
    account:false,
    deleteAccount:false,
    user:false,
    deleteUser:false,
    deleteCourse:false,
    course:false,
    categories:false,
    deleteCategories:false
  };
export const inputs = ["name", "lastName", "email", "password", "phone"];
