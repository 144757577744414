import React from "react";
import { useControls } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";
import ReplayIcon from "@mui/icons-material/Replay";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, IconButton } from "@mui/material";

import { styles } from "./ZoomableImageStyles";

const ZoomableImageControls = ({ placeControlsOnTopRight }) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <Box sx={placeControlsOnTopRight ? styles.topRightBoxStyles : {}}>
      <IconButton onClick={() => zoomIn()} sx={styles.iconStyles}>
        <ZoomInIcon />
      </IconButton>
      <IconButton onClick={() => zoomOut()} sx={styles.iconStyles}>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={() => resetTransform()} sx={styles.iconStyles}>
        <ReplayIcon />
      </IconButton>
    </Box>
  );
};

ZoomableImageControls.propTypes = {
  placeControlsOnTopRight: PropTypes.bool,
};

export default ZoomableImageControls;
