import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";

import styles from "./CustomBannerStyles";

const CustomBanner = ({
  customToastStyle,
  hideDuration,
  openSnackbar,
  onClose,
  severity,
  snackbarMessage,
}) => {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={hideDuration}
      onClose={onClose}
      message={snackbarMessage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      ContentProps={{
        sx: {
          ...styles.commonToastMessage,
          ...(severity === "success"
            ? styles.toastSuccessMessage
            : severity === "error"
            ? styles.toastErrorMessage
            : styles.toastMessage),
          ...customToastStyle,
        },
      }}
    />
  );
};

CustomBanner.propTypes = {
  customToastStyle: PropTypes.object,
  hideDuration: PropTypes.number,
  openSnackbar: PropTypes.bool,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  snackbarMessage: PropTypes.string,
};

export default CustomBanner;
