import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Carousel, { consts } from "react-elastic-carousel";
import { Typography, Box } from "@mui/material";

import UtilityModal from "../UtilityModal/UtilityModal";
import ZoomableImage from "../ZoomableImage/ZoomableImage";
import noImage from "../../img/No_Image_Available.jpg";
import chevronLeft from "../../img/chevronLeft.svg";
import chevronRight from "../../img/chevronRight.svg";
import { styles } from "./CustomMultiCarouselStyles";
import "./CustomMultiCarousel.css";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 400, itemsToShow: 2 },
  { width: 568, itemsToShow: 3 },
];
const newBreakPoints = [
  { width: 600, itemsToShow: 1 },
  { width: 900, itemsToShow: 1 },
];
const galleryBreakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 450, itemsToShow: 2.2 },
];

const CustomMultiCarousel = (props) => {
  const {
    defaultSelectedPhotoPosition,
    disableArrowKeys,
    galleryPreview,
    isZoomable,
    photos,
    preventOnImageClick,
    size,
  } = props;
  const carouselRef = useRef(null);
  const [imageModal, setImageModal] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  useEffect(() => {
    defaultSelectedPhotoPosition &&
      setSelectedPhotoIndex(defaultSelectedPhotoPosition);
  }, [defaultSelectedPhotoPosition]);

  useEffect(() => {
    if (
      carouselRef &&
      carouselRef.current &&
      (selectedPhotoIndex === 0 || selectedPhotoIndex)
    ) {
      carouselRef.current.goTo(selectedPhotoIndex);
    }
  }, [selectedPhotoIndex]);

  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        setSelectedPhotoIndex((prev) => (prev - 1 < 0 ? prev : prev - 1));
      } else if (e.key === "ArrowRight") {
        setSelectedPhotoIndex((prev) =>
          prev + 1 > photos.length - 1 ? prev : prev + 1
        );
      }
    },
    [photos]
  );

  // Set and cleanup the event listener
  useEffect(() => {
    if (!disableArrowKeys) {
      document.addEventListener("keydown", changeChild);
    }
    return function cleanup() {
      if (!disableArrowKeys) {
        document.removeEventListener("keydown", changeChild);
      }
    };
  });

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <Typography component="img" src={chevronLeft} alt="chevronLeft" />
      ) : (
        <Typography component="img" src={chevronRight} alt="chevronRight" />
      );

    return (
      <>
        {isEdge ? (
          <Box sx={styles.disabledArrowCarousel}>
            <Box>{pointer}</Box>
          </Box>
        ) : (
          <Box sx={styles.enabledArrowContainer}>
            <Box onClick={onClick} sx={styles.enabledArrowCarousel}>
              {pointer}
            </Box>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      {imageModal && (
        <>
          <UtilityModal
            openModal={imageModal}
            setCloseModal={setImageModal}
            selectedPhotoIndex={selectedPhotoIndex}
            photos={photos}
          />
        </>
      )}
      <Carousel
        ref={carouselRef}
        itemPosition={consts.CENTER}
        breakPoints={
          size === "large"
            ? newBreakPoints
            : galleryPreview
            ? galleryBreakPoints
            : breakPoints
        }
        renderArrow={galleryPreview ? myArrow : null}
      >
        {photos.length > 0 &&
          photos.map(
            (item, idx) =>
              !!item?.src.trim() && (
                <Box
                  sx={{
                    ...styles.imageBox,
                    ...(isZoomable ? styles.twoRowStyles : {}),
                  }}
                  key={idx}
                >
                  {isZoomable ? (
                    <ZoomableImage
                      key={idx}
                      alt="image"
                      customImageStyles={{
                        ...(size === "large"
                          ? styles.largeImage
                          : galleryPreview
                          ? styles.galleryImage
                          : styles.rectangleImage),
                        ...(isZoomable ? styles.noMarginTop : {}),
                      }}
                      includeZoomControls
                      src={item?.src}
                    />
                  ) : (
                    <Typography
                      key={idx}
                      onClick={() => {
                        if (!preventOnImageClick) {
                          setImageModal(true);
                          setSelectedPhotoIndex(idx);
                        }
                      }}
                      sx={
                        size === "large"
                          ? styles.largeImage
                          : galleryPreview
                          ? styles.galleryImage
                          : styles.rectangleImage
                      }
                      component="img"
                      src={item?.src}
                      alt="image"
                    />
                  )}
                </Box>
              )
          )}
        {photos.length === 0 && (
          <Typography
            sx={{ ...styles.rectangleImage, ...styles.noImage }}
            component="img"
            src={noImage}
            alt="image"
          />
        )}
      </Carousel>
    </>
  );
};

CustomMultiCarousel.propTypes = {
  defaultSelectedPhotoPosition: PropTypes.number,
  disableArrowKeys: PropTypes.bool,
  galleryPreview: PropTypes.bool,
  photos: PropTypes.array,
  preventOnImageClick: PropTypes.bool,
  size: PropTypes.string,
};

export default CustomMultiCarousel;
