const styles = {
  tableOuterContainer: {
    display: "flex",
    gap: "14px",
    flexDirection: "column",
  },
  userActionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    alignItems: "center",
  },
  createUserBtn: {
    width: "200px",
   
  },
  loaderStyle: {
    maxHeight: "calc(100vh - 320px)",
  },
  toastContainer: {
    position: "relative",
    width: "100%",
    marginTop: "10px",
  },
  customTableOuterContainer: {
    maxHeight: "calc(100vh - 330px)"
  },
buttonText:{
  fontWeight:"600"
}
};

export default styles;
