export const styles = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundImage: {
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};
