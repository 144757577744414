import React from "react";
import { Box, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { commonStyles } from "../../assests/commonStyles";
import { styles } from "./AccessDeniedStyles";

const AccessDenied = () => {
  return (
    <Box sx={{ ...commonStyles.pageWithGreyBg, ...styles.container }}>
      <Box sx={styles.accessDeniedBox}>
        <LockOutlinedIcon sx={styles.lockIcon} />
        <Typography sx={styles.accessDeniedMessage}>
          Your account is not enabled to access this feature.
        </Typography>
      </Box>
    </Box>
  );
};

export default AccessDenied;
