import {  WARM_ORANGE } from "../../consts/colors";

export const styles={
    toastContainer: {
        position: "relative",
        width: "100%",
        marginTop: "10px",
      },
      customToolbar: {
        height: "84px",
        position: "sticky",
      }, 
      label: {
        display: "block",
        fontSize: "14px",
        marginBottom: "10px",
        color: WARM_ORANGE,
        lineHeight:"20px",
        fontWeight:"500"
      },  
      orangeCheckBoxLabel:{
         color: WARM_ORANGE 
      }
}