import React  from "react";
import PropTypes from "prop-types";
import { Box, InputBase } from "@mui/material";
import BackButton from "../BackButton/BackButton";
import FilterMenu from "../FilterMenu/FilterMenu";
import ClearIcon from "@mui/icons-material/Clear";

import SearchIcon from "@mui/icons-material/Search";
import { styles } from "./SearchAndFilter.style";
import CustomButton from "../CustomButton/CustomButton";

const SearchAndFilter = ({
  defaultSelected,
  handleChangeText,
  search,
  isFilterRequired,
  searchPlaceHolder,
  headerTitle,
  filterOptions,
  selectedValue,
  isSmallFilter,
  customSearchAndFilterStyle,
  hideSearchBox,
  customToolbar,
  isBackButton,
  onBackButtonClick,
  stickyRef,
  isCloseButton,
  onCloseButton,
  actionButtonOne,
  actionButtonTwo,
  actionButtonTwoCustomStyles,
  onClickButtonOne,
  onClickButtonTwo,
  actionButtonTextFirst,
  actionButtonTextSecond,
  type,
  id,
  isButtomOneLoading,
  isButtonOneDisabled,
  buttonArray,
  titleSecond
}) => {
  return (
    <Box
      ref={stickyRef}
      position="sticky"
      style={{ ...styles.headerContainer, ...customSearchAndFilterStyle }}
    >
      <Box sx={{ ...styles.headerBox }}>
        <Box
          sx={{
            ...styles.toolbarStyle,
            ...customToolbar,
            ...{ position: "relative" },
          }}
        >
          {isCloseButton && (
            <Box sx={styles.closeIconContainer}>
              <Box
                onClick={() => {
                  onCloseButton && onCloseButton();
                }}
                sx={styles.closeButton}
              >
                <ClearIcon sx={styles.closeIcon} />
              </Box>
            </Box>
          )}
          <Box
            sx={styles.itemsContainer}
          >
            <Box sx={styles.backHeaderContainer}>
              {isBackButton && <BackButton handleClick={onBackButtonClick} />}
              {headerTitle && <Box sx={styles.heading}>{headerTitle}</Box>}
            </Box>
            {actionButtonOne && (
              <Box>
                {actionButtonOne && (
                  <CustomButton
                    {...(type ? { type: type } : {})}
                    {...(id ? { form: id } : {})}
                    onClick={() => {
                      onClickButtonOne && onClickButtonOne();
                    }}
                    showLoader={isButtomOneLoading}
                    isDisabled={isButtonOneDisabled}
                    classes={{
                      ...styles.commonBtnStyle,
                    }}
                  >
                    {actionButtonTextFirst}
                  </CustomButton>
                )}
                {actionButtonTwo && (
                  <CustomButton
                    onClick={() => {
                      onClickButtonTwo && onClickButtonTwo();
                    }}
                    classes={{
                      ...styles.commonBtnStyle,
                      ...actionButtonTwoCustomStyles,
                    }}
                  >
                    {actionButtonTextSecond}
                  </CustomButton>
                )}
              </Box>
            )}
            <Box sx={{display:"flex",alignItems:"center",gap:"1rem"}}>
            {buttonArray &&  buttonArray?.map(({handleClick,type,id,loader,isDisabled,text,isOrangeTheme,actionButtomCustomStyles
})=>(
              <CustomButton
              {...(type ? { type: type } : {})}
              {...(id ? { form: id } : {})}
              onClick={() => {
                handleClick && handleClick();
              }}
              showLoader={loader}
              isDisabled={isDisabled}
              classes={{
                ...styles.commonBtnStyle,
              ...(isOrangeTheme &&  styles.deleteBtn),
              ...(actionButtomCustomStyles)
              }}
            >
              {text}
            </CustomButton>
            ))
            }
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.headerContentContainer,
              ...(headerTitle
                ? {}
                : isFilterRequired
                ? { flex: 1, justifyContent: "space-between" }
                : { flex: 1, justifyContent: "flex-end" }),
            }}
          >
            {!hideSearchBox && (
              <Box sx={styles.search}>
                <Box sx={styles.searchIconWrapper}>
                  <SearchIcon sx={styles.searchIcon} />
                </Box>
                <Box
                  component={InputBase}
                  onChange={(event) => {
                    handleChangeText(event);
                  }}
                  value={search}
                  sx={styles.styledInputBase}
                  placeholder={searchPlaceHolder}
                />
              </Box>
            )}
            {isFilterRequired && (
              <FilterMenu
                filterOptions={filterOptions}
                selectedValue={selectedValue}
                {...(defaultSelected ? { defaultSelected } : {})}
                {...(isSmallFilter ? { isSmallFilter } : {})}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SearchAndFilter.propTypes = {
  defaultSelected: PropTypes.string,
  filterOptions: PropTypes.array,
  handleChangeText: PropTypes.func,
  headerTitle: PropTypes.string,
  isFilterRequired: PropTypes.bool,
  searchPlaceHolder: PropTypes.string,
  search: PropTypes.string,
  isSmallFilter: PropTypes.bool,
  selectedValue: PropTypes.object,
  hideSearchBox: PropTypes.bool,
  customToolbar: PropTypes.object,
  isBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  buttonArray: PropTypes.arrayOf(
    PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string,
      id: PropTypes.string,
      loader: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isOrangeTheme:PropTypes.bool
    })
  )
};

export default SearchAndFilter;
