import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import Slider from "../Slider/Slider";
import { ZOOM_CONSTANT } from "../../consts/constants";
import iconDisabledMinus from "../../img/iconDisabledMinus.svg";
import minusCircle from "../../img/minusCircle.svg";
import iconDisabledAdd from "../../img/iconDisabledAdd.svg";
import addCircle from "../../img/addCircle.svg";
import iconRotate from "../../img/iconRotate.svg";
import styles from "./ZoomSliderWithInfoStyles";

const ZoomSliderWithInfo = ({ setZoom, setRotation, zoom }) => {
  const zoomPercentage = Math.floor(
    ((zoom - ZOOM_CONSTANT.MIN_ZOOM) /
      (ZOOM_CONSTANT.MAX_ZOOM - ZOOM_CONSTANT.MIN_ZOOM)) *
      100
  );

  const zoomOutHandler = () => {
    if (zoom === ZOOM_CONSTANT.MIN_ZOOM) {
      return;
    }
    const decrement = +(zoom - ZOOM_CONSTANT.ZOOM_STEP).toFixed(1);
    setZoom(decrement);
  };

  const zoomInHandler = () => {
    if (zoom >= ZOOM_CONSTANT.MAX_ZOOM) {
      return;
    }
    const increment = +(zoom + ZOOM_CONSTANT.ZOOM_STEP).toFixed(1);
    setZoom(increment);
  };

  return (
    <Box sx={styles.zoomInfoContainer}>
      <Box sx={styles.sliderBox}>
        <Typography
          src={
            zoomPercentage === 0 ? iconDisabledMinus : minusCircle
          }
          component={"img"}
          alt="Zoom out"
          width={20}
          height={20}
          sx={zoomPercentage === 0 ? styles.disabledIcon : styles.zoomIcon}
          onClick={zoomOutHandler}
        />
        <Box sx={styles.zoomSlider}>
          <Slider
            maximumValue={ZOOM_CONSTANT.MAX_ZOOM}
            minimumValue={ZOOM_CONSTANT.MIN_ZOOM}
            onChange={setZoom}
            step={ZOOM_CONSTANT.ZOOM_STEP}
            value={+zoom}
          />
        </Box>
        <Typography
          component={"img"}
          src={
            zoomPercentage === 100 ? iconDisabledAdd : addCircle
          }
          alt="Zoom in"
          width={20}
          height={20}
          sx={zoomPercentage === 100 ? styles.disabledIcon : styles.zoomIcon}
          onClick={zoomInHandler}
        />
      </Box>
      <Typography
        sx={styles.percentageText}
      >{`${zoomPercentage}%`}</Typography>
      <Typography
        component={"img"}
        src={iconRotate}
        alt="Rotate"
        width={20}
        height={20}
        sx={styles.zoomIcon}
        onClick={() => {
          setRotation((prevRotation) => (prevRotation - 90) % 360);
        }}
      />
    </Box>
  );
};

ZoomSliderWithInfo.defaultProps = {
  setRotation: () => {},
  setZoom: () => {},
  zoom: 1,
};

ZoomSliderWithInfo.propTypes = {
  setRotation: PropTypes.func,
  setZoom: PropTypes.func,
  zoom: PropTypes.number,
};

export default ZoomSliderWithInfo;
