import { commonStyles } from "../../assests/commonStyles";
import { DIM_GREY, NIGHT_GREY, WHITE } from "../../consts/colors";

export const styles = {
  container: {
    width: "100%",
    display: "flex",
    gap: "1rem",
    ...commonStyles.hideScrollBar,
  },
  imageStyles: {
    width: "300px",
    cursor: "pointer",
    borderRadius: "0.5rem",
    maxHeight: "300px",
  },
  arrowKeysContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "1rem",
  },
  arrowKeysBox: {
    display: "flex",
    gap: "20px",
  },
  iconStyles: {
    cursor: "pointer",
    color: DIM_GREY,
  },
  deleteIconContainer: {
    position: 'absolute',
    display:"flex",
    alignItems:"center",
    top: -15,
    right: -15,
    backgroundColor: NIGHT_GREY,
    borderRadius: '50%',
    padding: '4px',
    cursor: 'pointer',
    Zindex:50
  },
  deleteIcon: {
    fill: WHITE,
  },
  videoStyles:{
    height:"120px",
    width:"150px",
    objectFit:"cover"
  },
  customVideoStyles:{
    objectFit:"cover",height:"120px",width:"120px",borderRadius: "8px"
  }
};
