import { useEffect, useState } from "react";

import { getImageSource } from "../utils/getImageSource";
import { FILE_MAX_SIZE, VIDEO_MAX_SIZE } from "../consts/constants";
import { SHOW_TOAST_MESSAGE_DURATION } from "../consts/toastShowDuration";
import {
  DOCUMENT_ACCEPTABLE_FORMAT_REGEX,
  IMAGE_ACCEPTABLE_FORMAT_REGEX,
  VIDEO_ACCEPTABLE_FORMAT_REGEX,
} from "../consts/regex";

const useUploadedFileValidations = ({
  isDocumentUpload,
  isVideoUpload,
} = {}) => {

  const [fileTooLargeError, setFileTooLargeError] = useState("");
  const [invalidFormatError, setInvalidFormatError] = useState("");
  const [nonUploadableImageError, setNonUploadableImageError] = useState("");

  useEffect(() => {
    let timerId = null;
    if (fileTooLargeError || invalidFormatError || nonUploadableImageError) {
      timerId = setTimeout(() => {
        fileTooLargeError && setFileTooLargeError("");
        invalidFormatError && setInvalidFormatError("");
        nonUploadableImageError && setNonUploadableImageError("");
      }, SHOW_TOAST_MESSAGE_DURATION);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [fileTooLargeError, invalidFormatError, nonUploadableImageError]);

  const getMimeType = () => {
    if (isDocumentUpload) return DOCUMENT_ACCEPTABLE_FORMAT_REGEX;
    if (isVideoUpload) return VIDEO_ACCEPTABLE_FORMAT_REGEX;
    return IMAGE_ACCEPTABLE_FORMAT_REGEX;
  };

  const initiateFileUpload = ({ onLoad, resetUploadInput, uploadedFile }) => {
    setFileTooLargeError("");
    setInvalidFormatError("");
    setNonUploadableImageError("");
    if (uploadedFile) {
      if (!uploadedFile.type.match(getMimeType())) {
        setInvalidFormatError(
          isDocumentUpload
            ? "Only PDF/JPG format is allowed":
            isVideoUpload?"only MP4/PDF format is allowed"
            : "Only JPEG, PNG, SVG formats are allowed"
        );
        resetUploadInput && resetUploadInput();
        return;
      }
      if (isVideoUpload && uploadedFile.size > VIDEO_MAX_SIZE) {
        setFileTooLargeError("Uploaded video must be less than 1.2GB");
        resetUploadInput && resetUploadInput();
        return;
      }
      if (!isVideoUpload && uploadedFile.size > FILE_MAX_SIZE) {
        setFileTooLargeError("Uploaded file must be less than 5MB");
        resetUploadInput && resetUploadInput();
        return;
      }
      if (isDocumentUpload || isVideoUpload) {
        onLoad && onLoad({ uploadedFile });
        return;
      }
      const img = document.createElement("img");
      img.src = getImageSource(uploadedFile);
      img.alt = uploadedFile.name;
      img.onload = () => {
        onLoad && onLoad({ uploadedFile });
      };
      img.onerror = () => {
        setNonUploadableImageError("Only JPEG, PNG, SVG formats are allowed");
      };
    }
    resetUploadInput && resetUploadInput();
  };

  return {
    fileTooLargeError,
    initiateFileUpload,
    invalidFormatError,
    nonUploadableImageError,
    setFileTooLargeError,
    setInvalidFormatError,
    setNonUploadableImageError,

  };
};

export default useUploadedFileValidations;
