import  { useState } from 'react'
import { validatePassword } from '../../passwordValidate';
import { PASSWORD_CONSTRAINTS_FAILED_MESSAGE } from '../../../consts/constants';

const useValidatePassword = () => {
    const [passwordErrorMessage,setPasswordErrorMessage]=useState("");
    const passwordCheck=(password,isEmptyAllowed=false)=>{
       const isValid=   (isEmptyAllowed? validatePassword(password,true) : validatePassword(password));
       !isValid ? setPasswordErrorMessage(PASSWORD_CONSTRAINTS_FAILED_MESSAGE) :setPasswordErrorMessage("");
    }
    return {
        passwordCheck,
        passwordErrorMessage,
        setPasswordErrorMessage
    }
}

export default useValidatePassword