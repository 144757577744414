/* import providers */
import authProvider from "./auth/authProvider";
import userProvider from "./user/userProvider";
import DeleteResultProvider from "./actions/deleteAction";
import categoriesProvider from "./categories/categoriesProvider";
// Import other created Providers and add them here -
const providers = [authProvider, userProvider, DeleteResultProvider,categoriesProvider];

const combineProviders = (components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

// Combining multiple providers to single provider - this will be wrapped around App.js
export default combineProviders(providers);
