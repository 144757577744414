import { urlService } from "./urlService";
import { REC_PER_PAGE, ROW_PER_PAGE_OPTIONS } from "../consts/constants";

export const getValidRowPerPage = () => {
  const rowCount = +urlService.getQueryStringValue("rows-per-page");
  if (ROW_PER_PAGE_OPTIONS.includes(rowCount)) {
    return rowCount;
  }
  urlService.setQueryStringValue("rows-per-page", REC_PER_PAGE)
  return REC_PER_PAGE;
};
