import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CustomButton from "../../components/CustomButton/CustomButton";
import CustomInputBase from "../../components/CustomInputBase/CustomInputBase";
import Label from "../../components/Label/Label";
import Toast from "../Toast/Toast";
import { AuthContext } from "../../globalContext/auth/authProvider";
import useLoginUser from "../../utils/apiService/Hooks/useLoginUser";
import navigations from "../../routes/routeNames";
import ciiLogo from "../../img/ciiVerticalLogo.png";
import { SHOW_TOAST_MESSAGE_DURATION } from "../../consts/toastShowDuration";
import { styles } from "./LoginFormStyles";
import { setLogoutInfo } from "../../globalContext/auth/authActions";

const LoginForm = () => {
  const navigate = useNavigate();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [authState, authDispatch] = useContext(AuthContext);

  const {
    errorWhileLoggingIn,
    handleUserLogin,
    isLoggingIn,
    setErrorWhileLoggingIn,
  } = useLoginUser();

  const loginHandler = (e) => {
    e.preventDefault();
    const details = {
      email: enteredEmail,
      password: enteredPassword,
    };
    handleUserLogin(details);
  };

  const loginApiFailedCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (authState.logoutData) {
      authDispatch(setLogoutInfo({}));
      return;
    }
    setErrorWhileLoggingIn("");
  };

  return (
    <Box component="form" onSubmit={loginHandler} sx={styles.loginSubContainer}>
      <Grid sx={styles.container}>
        <Grid item>
          <Typography
            component="img"
            src={ciiLogo}
            sx={styles.logoStyles }
          />
        </Grid>
        <Grid item sx={styles.loginFormContainer}>
        <Box sx={styles.inputContainer}>
            <Grid item xs={12}>
              <Box sx={styles.labelAndInputBox}>
                <Label labelInputProp={styles.labelInputProp}>
                  E-Mail Address
                </Label>
                <CustomInputBase
                  type="email"
                  value={enteredEmail}
                  setValue={setEnteredEmail}
                  isRequire
                  isFieldOnPurpleBackground
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={styles.labelAndInputBox}>
                <Label labelInputProp={styles.labelInputProp}>Password</Label>
                <CustomInputBase
                  type={showPassword ? "text" : "password"}
                  value={enteredPassword}
                  setValue={setEnteredPassword}
                  showPasswordVisiblityOffIcon={!showPassword}
                  showPasswordVisiblityOnIcon={showPassword}
                  iconFunction={setShowPassword}
                  isRequire
                  isFieldOnPurpleBackground
                />
              </Box>
            </Grid>
          </Box>
          <Grid container>
            <Grid item xs={12} sx={styles.forgetPasswordContainer}>
              <Typography
                onClick={() => navigate(navigations.FORGOT_PASSWORD)}
                sx={styles.forgetPasswordText}
              >
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={styles.loginButtonContainer}>
              <CustomButton
                type="submit"
                classes={styles.loginButton}
                showLoader={isLoggingIn}
              >
                Log In
              </CustomButton>
            </Grid>
          </Grid>
          {(!!!!authState?.logoutData?.message || !!errorWhileLoggingIn) && (
            <Grid container sx={styles.errorMessageContainer}>
              <Grid item xs={12}>
                <Toast
                  open={!!errorWhileLoggingIn || !!authState?.logoutData}
                  closeHandler={loginApiFailedCloseHandler}
                  alertMessage={
                    errorWhileLoggingIn || authState?.logoutData?.message
                  }
                  severity={authState?.logoutData?.status || "error"}
                  autoHideDuration={SHOW_TOAST_MESSAGE_DURATION}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
