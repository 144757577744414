import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomModal from "../../components/CustomModal/CustomModal";
import EditViewForm from "../../components/EditViewForm/EditViewForm";
import useValidatePassword from "../../utils/apiService/Hooks/useValidatePassword";
import { columns, formFields } from "./SendCredentialConstant";
import { styles } from "./SendCredentialStyles";
import ClearIcon from "@mui/icons-material/Clear";

const SendCredential = ({ handleCloseModal, onSubmit,loading }) => {
  const [formData, setFormData] = useState(formFields);
  const { passwordCheck, passwordErrorMessage, setPasswordErrorMessage } =
    useValidatePassword();

  const updatedColumns = columns.map((item) => {
    if (item.name === "autogenerate") {
      return {
        ...item,
        handleChange: (value) => {
          if (value) {
            setPasswordErrorMessage("");
            setFormData((prevData) => ({
              ...prevData,
              password: "",
            }));
          }
          setFormData((prevData) => ({
            ...prevData,
            autogenerate: value,
          }));
        },
      };
    } else {
      return {
        ...item,
        validate:()=>{
          passwordCheck(formData.password,true)
        },
        isDisabled: () => {
          return formData["autogenerate"] ;
        },
      };
    }
  });

  const isButtonDisabled = () => {
    if (formData["autogenerate"]===false && !formData.password) {
      return true;
    }
    return false;
  };
  
  return (
    <CustomModal
      openModal
      maxWidth="sm"
      omitCloseButton
      closeModalHandler={() => {
        handleCloseModal && handleCloseModal();
      }}
    >
      <Box sx={styles.closeIconContainer}>
        <Box
          onClick={() => handleCloseModal && handleCloseModal()}
          sx={styles.closeButton}
        >
          <ClearIcon sx={styles.closeIcon} />
        </Box>
      </Box>

      <EditViewForm
        isEditable={true}
        columns={updatedColumns}
        formData={formData}
        setFormData={setFormData}
        errorForm={{
          password: passwordErrorMessage,
        }}
        isDisabled={isButtonDisabled() || passwordErrorMessage}
        onSubmitClick={(e) => {
          e.preventDefault()
          onSubmit && onSubmit(formData.password);
        }}
        isloading={loading}
        customFormStyles={styles.formStyle}
      />
    </CustomModal>
  );
};

export default SendCredential;
