import axios from "axios";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import getToken from "../../auth/getToken";
import { config } from "../../consts/constants";
import { SESSION_EXPIRED_MESSAGE } from "../../consts/errorMessages";
import { UNAUTHORIZED_USER } from "../../api/statusCodes";
import { REFRESH_TOKEN } from "../../api/apiEndpoints";
import { setAuthToken } from "../../globalContext/auth/authActions";
import { useContext } from "react";
import { AuthContext } from "../../globalContext/auth/authProvider";
import { UserContext } from "../../globalContext/user/userProvider";
import { setUserData } from "../../globalContext/user/userActions";

const useAxiosInstance = () => {
  const { logoutHandler } = useLogoutUser();
   const [,authDispatch]=useContext(AuthContext);
   const [, userDispatch] = useContext(UserContext);

  const axiosInstance = axios.create({
    baseURL: config.url.API_URL,
  });

  axiosInstance.interceptors.request.use((request) => {
    request.headers["Content-Type"] = "application/json";
    request.headers["Authorization"] = getToken();
    request.headers["ngrok-skip-browser-warning"]='69420';
    return request;
  });


  const refreshAccessToken = async (accessToken) => {
    try {
      console.log("old token ",accessToken);
      const response = await axios.post(`${config.url.API_URL}${REFRESH_TOKEN}`, {
        token: accessToken,
      });
      const newAccessToken = response.data;
      console.log("newAccessToken",newAccessToken);

      return newAccessToken;
    } catch (error) {
      throw error;
    }
  };

  axiosInstance.interceptors.response.use(
    (response) => {

      return response;
    },
    async (error) => {
         const originalRequest = error.config;
      if (error.response?.status === UNAUTHORIZED_USER && !originalRequest._retry) {
        try {
          originalRequest._retry = true;
          const token=getToken();
          const newAccessToken = await refreshAccessToken(token);
          authDispatch(setAuthToken(newAccessToken));
          
          originalRequest.headers["Authorization"]=newAccessToken;
          
          console.log("new request",originalRequest);
          return axiosInstance(originalRequest); 
        } catch (error) {
          logoutHandler({
            logoutDetails: {
              message: SESSION_EXPIRED_MESSAGE,
              state: "error",
            },
          });

          userDispatch(setUserData(null));

        }


     

        return;
      }
      return Promise.reject(error); 
    }
  );

  return { axiosInstance };
};

export default useAxiosInstance;