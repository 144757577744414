import { WHITE } from "../../consts/colors";

export const styles = {
  loginParentConatiner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  loginFormContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px"
  },
  copyRightsContainer: {
    marginBottom: "40px",
    maxWidth: "298px",
    alignSelf: "center",
  },
  copyRightText: {
    fontSize: "12px",
    color: WHITE,
    textAlign: "center",
  },
};
