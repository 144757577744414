import React, { useContext,useState } from "react";
import { Box, Typography } from "@mui/material";

import Base from "../../Layout/Base";
import CustomModal from "../../components/CustomModal/CustomModal";
import CustomPrompt from "../../components/CustomPrompt/CustomPrompt";
import EditUserDetails from "../../components/EditUserDetails/EditUserDetails";
import EditViewForm from "../../components/EditViewForm/EditViewForm";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import useDelete from "../../utils/apiService/Hooks/useDelete";
import { columns } from "./ViewUserDetailsConstants";
import { UserContext } from "../../globalContext/user/userProvider";
import { GET_USER_LIST } from "../../api/apiEndpoints";
import { commonStyles, modalCommonStyles } from "../../assests/commonStyles";
import styles from "./ViewUserDetailsStyle";
import { urlService } from "../../utils/urlService";
import CustomCheckBoxGroup from "../../components/CustomCheckBoxGroup/CustomCheckBoxGroup";
import { checkBoxGroup } from "../CreateUsers/CreateUserConstant";
import useAuthorisation from "../../CustomHooks/useAuthorisation";
import useGettingUserInfo from "../../api/user/useGettingUserInfo";
import useUpdateData from "../../utils/apiService/Hooks/useUpdateData";

const ViewUserDetails = ({
  closeUserDetailsModal,
  setUserList,
  userDetails,
  userList,
  setUserSuccess,
  setDeleteUserSuccess,
  setTotalCount,
}) => {
  const { getUserDetails } = useGettingUserInfo();
  const { isDeleteUsersAccess } = useAuthorisation();
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [formData, setFormData] = useState(userDetails);
  const {
    error: errorWhileDeletingUser,
    isDeleting: isDeletingUser,
    handleDelete: handleDeleteUser,
  } = useDelete();
  const [userState] = useContext(UserContext);

  const deleteUserSuccessHandler = () => {
    setUserList((prevDetails) => {
      const users = [...prevDetails.data.data.users];
      const updatedUsers = users.filter((item) => {
        return item.id !== +urlService.getQueryStringValue("user-id");
      });
      const updatedState = {
        ...prevDetails,
      };
      updatedState.data.data.users = updatedUsers;
      return updatedState;
    });
    setTotalCount((prev) => prev - 1);

    closeUserDetailsModal();
    setOpenDeleteUserModal(false);
    setDeleteUserSuccess(true);
    if (userState?.userInfo?.data?.data?.id === userDetails?.id) {
      getUserDetails();
    }
  };

  const formattedFormData = () => {
    const { access, ...otherFormData } = formData;
    const newObj = { ...otherFormData };
    if (access != null) {
      access.split(",").map((item) => {
        if (!!item?.trim()) {
          newObj[item] = true;
        }
        return item;
      });
    }
    return newObj;
  };

  const updatedColumns = () => {
    const newColumns = [...columns];
    const newCheckboxColumns = [...checkBoxGroup];
    const obj = {
      component: "Custom",
      customJsx: (
        <Box>
          <Typography sx={styles.label}>Admin Access Type</Typography>
          <CustomCheckBoxGroup
            isReadOnly={true}
            columns={newCheckboxColumns}
            setFormData={setFormData}
            formData={formattedFormData()}
          />
        </Box>
      ),
      isFull: true,
    };
    newColumns.push(obj);
    return newColumns;
  };
  const { error, loading, handleUpdate, setError } = useUpdateData();

  return (
    <>
      <CustomModal
        openModal={!!userDetails && !openDeleteUserModal}
        fullWidth
        maxWidth="lg"
        closeModalHandler={closeUserDetailsModal}
      >
        {!editModal ? (
          <Base
            customStyles={{
              ...commonStyles.tableContainer,
              ...{ overflow: "visible" },
            }}
          >
            {
              <Box>
                <Box>
                  <SearchAndFilter
                    headerTitle={"User Details"}
                    hideSearchBox
                    customToolbar={styles.customToolBarStyles}
                    customSearchAndFilterStyle={modalCommonStyles.headerStyle}
                    isCloseButton
                    onCloseButton={() => {
                      closeUserDetailsModal();
                    }}
                    actionButtonTextFirst={"Edit"}
                    actionButtonTextSecond={"Delete"}
                    onClickButtonOne={() => {
                      setEditModal(true);
                    }}
                    onClickButtonTwo={() => {
                      setOpenDeleteUserModal(true);
                    }}
                    actionButtonTwoCustomStyles={styles.deleteBtn}
                    buttonArray={[
                      {
                        text: "Edit",
                        handleClick: () => {
                          setEditModal(true);
                        },
                      },
                      ...(isDeleteUsersAccess ? [{
                        text: "Delete",
                        handleClick: () => {
                          setOpenDeleteUserModal(true);
                        },
                        isOrangeTheme: true,
                      }] : []),
                    ]}
                  />
                </Box>
                <Box sx={styles.userDetailsParentContainer}>
                  <Box sx={styles.userDetailsContainer}>
                    {!!userDetails && (
                      <EditViewForm
                        isThreeColumns
                        columns={updatedColumns()}
                        formData={formattedFormData()}
                        isReadOnly={true}
                        setFormData={setFormData}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            }
          </Base>
        ) : (
          <Base customStyles={{ ...commonStyles.tableContainer }}>
            <Box></Box>
            {!!userDetails && (
              <>
                <EditUserDetails
                  data={userDetails}
                  setEditModal={setEditModal}
                  closeUserDetailsModal={closeUserDetailsModal}
                  setUserList={setUserList}
                  userList={userList}
                  setUserSuccess={setUserSuccess}
                  error={error}
                  loading={loading}
                  handleUpdate={handleUpdate}
                  setError={setError}
                />
              </>
            )}
          </Base>
        )}
      </CustomModal>
      {openDeleteUserModal &&
        (!errorWhileDeletingUser ? (
          <CustomPrompt
            fullWidth
            maxWidth="sm"
            showLoader={isDeletingUser}
            hideModalImage
            open={openDeleteUserModal}
            heading="Delete User?"
            descriptionLineOne={
              userState?.userInfo?.data?.data?.id === userDetails.id
                ? "Are you sure you want to delete your account ?"
                : "Are you sure you want to delete this user?"
            }
            buttonOneText={"Delete"}
            buttonTwoText={"Close"}
            omitCloseButton
            onButtonTwoClick={() => setOpenDeleteUserModal(false)}
            onButtonOneClick={() =>
              handleDeleteUser(`${GET_USER_LIST}/${userDetails.id}`, () =>
                deleteUserSuccessHandler()
              )
            }
            buttonOneCustomStyles={styles.deleteBtn}
          />
        ) : (
          <CustomModal
            openModal={!!errorWhileDeletingUser}
            fullWidth
            maxWidth="xs"
          >
            <ErrorComponent
              errorHeading="Error"
              errorText={errorWhileDeletingUser}
              onRetry={() => {
                handleDeleteUser(`${GET_USER_LIST}/${userDetails.id}`, () =>
                  deleteUserSuccessHandler()
                );
              }}
              customContainerStyles={styles.errorContainer}
              closeModalHandler={() => {
                closeUserDetailsModal();
              }}
            />
          </CustomModal>
        ))}
    </>
  );
};

export default ViewUserDetails;
