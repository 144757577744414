export const formFields={
    autogenerate:false,
    password:""
}

export const columns=[
    {
     
        component: "TextField",
        type: "password",
        label: "Password",
        name: "password",
        autoCompleteOff: true,
        isFull:true
        },
    {
        label:"Auto generate password",
        name:"autogenerate",
        component:"CheckBox",
        isFull:true,
        noLabel:true
    }
]