import { DUSTY_WHITE, WHITE } from "../../consts/colors";

export const styles = {
  imageBox: {
    display: "flex",
    alignItems: "center",
  },
  twoRowStyles: {
    flexDirection: "column",
  },
  rectangleImage: {
    width: "175px",
    height: "134px",
    objectFit: "contain",
    backgroundColor: WHITE,
    cursor: "pointer",
  },
  noImage: {
    cursor: "default",
  },
  largeImage: {
    maxWidth: "100%",
    maxHeight: "calc(100vh - 100px)",
    textAlign: "center",
    marginTop: "5%",
    "@media (max-width : 600px)": {
      maxWidth: "100%",
    },
  },
  noMarginTop: {
    marginTop: 0,
  },
  galleryImage: {
    width: "213px",
    height: "150px",
    borderRadius: "0px",
    objectFit: "contain",
    backgroundColor: WHITE,
    cursor: "pointer",
  },
  modal: {
    backgroundColor: DUSTY_WHITE,
  },
  disabledArrowCarousel: {
    display: "flex",
    alignItems: "center",
    cursor: "not-allowed",
  },
  enabledArrowContainer: {
    display: "flex",
    alignItems: "center",
  },
  enabledArrowCarousel: {
    cursor: "pointer",
  },
};
