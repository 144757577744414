import { createContext, useReducer } from "react";

import { getItem } from "../../services/localStorageService";
import { AUTH_KEY } from "../../consts/constants";
import * as types from "./types";

const initialState = {
  auth: getItem(AUTH_KEY),
  id: getItem("id"),
  role:getItem("role"),
  logoutData: {
    message: "",
    state: "",
  },
};

const authReducer = (state, action) => {
  switch (action.type) {
    case types.SET_AUTH:
      return {
        ...state,
        ...{ auth: action.payload.authToken,
            id:action.payload.id,
            role:action.payload?.role
       },
      };
    case types.CLEAR_AUTH:
      return {};
      case types.SET_AUTHTOKEN:
        return {
          ...state,
          auth:action.payload
        }
    case types.SET_LOGOUT_INFO:
      return {
        auth:"",
        id:null,
        role:"",
        ...{ logoutData: action.payload },
      };
    default:
      return state;
  }
};

export const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [authState, authDispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={[authState, authDispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
