import React from "react";
import PropTypes from "prop-types";

import DarkTooltip from "../CustomTooltip/DarkTooltip/DarkTooltip";
import LightTooltip from "../CustomTooltip/LightTooltip/LightTooltip";
import GreenTooltip from "../CustomTooltip/GreenTooltip/GreenTooltip";

const TooltipWrapper = ({ children, darkMode,GreenMode, ...otherProps }) => {
  const TooltipComponent = GreenMode? GreenTooltip: darkMode ? DarkTooltip : LightTooltip;

  return <TooltipComponent {...otherProps}>{children}</TooltipComponent>;
};

TooltipWrapper.propTypes = {
  children: PropTypes.node,
  darkMode: PropTypes.bool,
  description: PropTypes.string,
};

export default TooltipWrapper;
