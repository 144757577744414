import React from "react";
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from "@mui/material";
import { APP_THEME_THIRTEEN } from "../../../consts/colors";

const DarkTooltip = styled(({ className, backgroundColor, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, backgroundColor, ...props }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:APP_THEME_THIRTEEN || theme.palette.common.black,
    fontSize: 14,
    ...(props.arrow && { paddingBottom: "6px",backgroundColor:APP_THEME_THIRTEEN }),
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: backgroundColor || theme.palette.common.black,
    },
  },
}));

export default DarkTooltip;
