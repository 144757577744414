import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import CustomButton from "../CustomButton/CustomButton";
import useHandleEscapeKeyEvent from "../../CustomHooks/useHandleEscapeKeyEvent";
import { commonStyles, modalCommonStyles } from "../../assests/commonStyles";
import { styles } from "./CustomPromptStyles";

const CustomPrompt = ({
  open,
  fullWidth,
  maxWidth,
  showLoader,
  omitCloseButton,
  closeModalHandler,
  modalIcon,
  modalIconAlt,
  heading,
  descriptionLineOne,
  descriptionLineTwo,
  descriptionLineThree,
  buttonOneText,
  onButtonOneClick,
  buttonOneCustomStyles,
  buttonTwoText,
  onButtonTwoClick,
  buttonTwoCustomStyles,
  customStylesForLineTwo,
  customStylesForLineThree,
  dangerLineThree,
  isButtonOneDangerButton,
  hideModalImage = false,
}) => {
  const onEscapePress = () => {
    if (omitCloseButton) {
      return;
    }
    closeModalHandler && closeModalHandler();
  };

  useHandleEscapeKeyEvent({ onEscapePress });

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth ? maxWidth : "sm"}
      sx={modalCommonStyles.modal}
      open={open}
    >
      <Box sx={styles.container}>
        {showLoader ? (
          <Box
            sx={{
              ...modalCommonStyles.loaderContainer,
              ...styles.loaderStyles,
            }}
          >
            <CircularProgress sx={modalCommonStyles.loader} />
          </Box>
        ) : (
          <>
            {omitCloseButton ? null : (
              <Box sx={styles.buttonContainer}>
                <Box onClick={closeModalHandler} sx={styles.closeButton}>
                  <ClearIcon sx={styles.closeIcon} />
                </Box>
              </Box>
            )}
            <Box>
              <Box sx={styles.headingBox}>
                <Typography sx={commonStyles.heading}>{heading}</Typography>
              </Box>
              <Box sx={commonStyles.highlightBox}>
                <Box sx={{ ...styles.row, ...commonStyles.mobileModalRow }}>
                  {modalIcon && (
                    <Box>
                      <Typography
                        component="img"
                        sx={styles.image}
                        src={modalIcon}
                        alt={modalIconAlt || "image"}
                      />
                    </Box>
                  )}
                  {!hideModalImage && (
                    <Box>
                      <Typography
                        component="img"
                        sx={styles.image}
                        src={modalIcon}
                        alt={modalIconAlt || "image"}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography
                      sx={{
                        ...styles.lineOne,
                        ...commonStyles.mobileModalText,
                        ...(descriptionLineTwo ? styles.marginBottom : {}),
                      }}
                    >
                      {descriptionLineOne}
                    </Typography>
                    {!!descriptionLineTwo && (
                      <Typography
                        sx={{
                          ...styles.descriptionOtherLines,
                          ...commonStyles.mobileModalText,
                          ...(descriptionLineThree ? styles.marginBottom : {}),
                          ...customStylesForLineTwo,
                        }}
                      >
                        {descriptionLineTwo}
                      </Typography>
                    )}
                    {!!descriptionLineThree && (
                      <Typography
                        sx={{
                          ...styles.descriptionOtherLines,
                          ...commonStyles.mobileModalText,
                          ...(dangerLineThree ? styles.alert : {}),
                          ...customStylesForLineThree,
                        }}
                      >
                        {descriptionLineThree}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={commonStyles.buttonBox}>
                  <CustomButton
                    onClick={onButtonOneClick}
                    classes={{
                      ...commonStyles.whiteButtonStyles,
                      ...buttonOneCustomStyles,
                    }}
                    dangerButton={isButtonOneDangerButton}
                  >
                    {buttonOneText}
                  </CustomButton>
                  {!!buttonTwoText && (
                    <CustomButton
                      onClick={onButtonTwoClick}
                      classes={{
                        ...commonStyles.whiteButtonStyles,
                        ...commonStyles.buttonTwoStyles,
                        ...buttonTwoCustomStyles,
                      }}
                    >
                      {buttonTwoText}
                    </CustomButton>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

CustomPrompt.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  showLoader: PropTypes.bool,
  omitCloseButton: PropTypes.bool,
  closeModalHandler: PropTypes.func,
  modalIcon: PropTypes.string,
  modalIconAlt: PropTypes.string,
  heading: PropTypes.string,
  descriptionLineOne: PropTypes.string,
  descriptionLineTwo: PropTypes.string,
  descriptionLineThree: PropTypes.string,
  buttonOneText: PropTypes.string,
  onButtonOneClick: PropTypes.func,
  buttonOneCustomStyles: PropTypes.object,
  buttonTwoText: PropTypes.string,
  onButtonTwoClick: PropTypes.func,
  buttonTwoCustomStyles: PropTypes.object,
  customStylesForLineTwo: PropTypes.object,
  customStylesForLineThree: PropTypes.object,
  dangerLineThree: PropTypes.bool,
  isButtonOneDangerButton: PropTypes.bool,
  hideModalImage: PropTypes.bool,
};

export default CustomPrompt;
