import { APP_PRIMARY_THEME_COLOR} from "../../consts/colors";

export const styles = {
  loaderContainerStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  },
  loaderStyles: {
    color:APP_PRIMARY_THEME_COLOR ,
  },
};
