import { APP_LIGHTER_PRIMARY_THEME, APP_THEME_TWELVE, WHITE } from "../../consts/colors";

export const styles = {
  drawerStyle: {
    width: 80,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 80,
      boxSizing: "border-box",
      height: "calc(100vh - 80px)",
      border: "1px",
      borderColor: APP_THEME_TWELVE,
      position:"fixed",
      top:"80px",
      overFlowY:"scroll",
      backgroundColor: APP_THEME_TWELVE
    },
  },
  listItemIcon: {
    padding: "8px",
    marginBottom: "12px",
    alignItems: "center",
    justifyContent: "center"
  },
  sideBarIcon: {
    height: "32px",
    width: "32px",
  },
  listItemButton: {
    justifyContent: "center",
    "&:hover": {
      backgroundColor: APP_LIGHTER_PRIMARY_THEME,
    },
  },
  displaySeparator: {
    borderBottom: `1px solid ${WHITE}`,
    margin: "0px 14px",
  },
  listItemButtonSelected: {
    justifyContent: "center",
    backgroundColor: APP_LIGHTER_PRIMARY_THEME,
    "&:hover": {
      backgroundColor: APP_LIGHTER_PRIMARY_THEME,
    },
  },
};
