import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import getToken from "../../../auth/getToken";
import NovaAPI from "../../../Axios/NovaAPI";
import navigations from "../../../routes/routeNames";
import { UserContext } from "../../../globalContext/user/userProvider";
import { AuthContext } from "../../../globalContext/auth/authProvider";
import {
  clearAuth,
  setLogoutInfo,
} from "../../../globalContext/auth/authActions";
import { setUserData } from "../../../globalContext/user/userActions";
import { API_STATUS } from "../../../consts/constants";
import { SUCCESS_STATUS } from "../../../api/statusCodes";
import { GENERIC_GET_API_FAILED_ERROR_MESSAGE } from "../../../consts/errorMessages";
import { LOGOUT } from "../../../api/apiEndpoints"
const useLogoutUser = () => {
  const navigate =useNavigate()
  const [, authDispatch] = useContext(AuthContext);
  const [, userDispatch] = useContext(UserContext);
  const [logoutApiStatus, setLogoutApiStatus] = useState(API_STATUS.IDLE);
  const [errorLoggingOut, setErrorLoggingOut] = useState("");

  const logoutHandler = async ({ logoutDetails }) => {
    const authToken = getToken();
    try {
      setLogoutApiStatus(API_STATUS.LOADING);
      errorLoggingOut && setErrorLoggingOut("");
      const res = await NovaAPI.post(LOGOUT,{}, {
        headers: {
          "Authorization": authToken,
        },
      });
      if (res.status === SUCCESS_STATUS) {
        setLogoutApiStatus(API_STATUS.SUCCESS);
        return;
      }
      setLogoutApiStatus(API_STATUS.ERROR);
      setErrorLoggingOut(GENERIC_GET_API_FAILED_ERROR_MESSAGE);
    } catch (err) {
      setLogoutApiStatus(API_STATUS.ERROR);
      const errorMessage =
        err.response?.data?.message || GENERIC_GET_API_FAILED_ERROR_MESSAGE;
      setErrorLoggingOut(errorMessage);
    } finally {
      authDispatch(clearAuth());
      userDispatch(setUserData(null));
      logoutDetails && authDispatch(setLogoutInfo(logoutDetails));
      navigate(navigations.LOGIN);
    }
  };
  const isLoggingUserOut = logoutApiStatus === API_STATUS.LOADING;
  const isSuccessfullyLoggedOut = logoutApiStatus === API_STATUS.SUCCESS;
  const errorWhileLoggingOut = logoutApiStatus === API_STATUS.ERROR;

  useEffect(() => {
    return () => {
      setLogoutApiStatus(API_STATUS.IDLE);
      setErrorLoggingOut("");
    };
  }, []);

  return {
    errorLoggingOut,
    errorWhileLoggingOut,
    logoutHandler,
    isLoggingUserOut,
    isSuccessfullyLoggedOut,
    logoutApiStatus,
    setErrorLoggingOut,
  };
};

export default useLogoutUser;
