const iconStyle = {
  height: "20px",
  width: "20px",
};
const styles = {
  zoomInfoContainer: {
    marginTop: "32px",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "0px 20px"
  },
  sliderBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: 1,
  },
  zoomIcon: {
    cursor: "pointer",
    ...iconStyle,
  },
  disabledIcon: {
    ...iconStyle,
    cursor: "default",
  },
  zoomSlider: {
    flex: 1,
  },
  percentageText: {
    fontSize: "14px",
    minWidth: "36px",
  },
};

export default styles;
