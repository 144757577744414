import {
  GONDOLA,
  GREY95,
  GREY_CHATEAU,
  VERY_LIGHT_GREY,
  WHITE,
  APP_PRIMARY_THEME_COLOR,
  BROWN_HEADING,
  RED_RATING,
  LIGHT_RED,
} from "../../consts/colors";

export const styles = {
  headerContainer: {
    width: "100%",
    height: "80px",
    marginBottom: "20px",
    marginTop: "16px",
    top:"0px",
    position:"sticky",
  
  },
  headerBox: {
    zIndex: 1,
  },
  headerContentContainer: {
    display: "flex",
    gap: "16px",
  },

  backHeaderContainer: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  toolbarStyle: {
    backgroundColor: WHITE,
    padding: "20px 28px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    flex: 1,
  },
  search: {
    position: "relative",
    borderRadius: "6px",
    backgroundColor: GREY95,
    marginRight: "16px",
    marginLeft: 0,
    width: "100%",
    "@media (min-width:600px)": {
      marginLeft: "24px",
      width: "auto",
    },
  },
  searchIcon: {
    color: GREY_CHATEAU,
  },
  searchIconWrapper: {
    padding: "0 16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    fontSize: "20px",
    fontWeight: "700",
    lineheight: "24px",
    letterSpacing: "0em",
    textalign: "left",
    display: { xs: "block", sm: "block" },
    color: APP_PRIMARY_THEME_COLOR,
  },
  styledInputBase: {
    height: "44px",
    padding: "14px 20px !important",
    "& .MuiInputBase-input": {
      paddingLeft: "calc(1em + 12px)",
      transition: "width 0.3s ease",
      width: "100%",
      "@media (min-width:960px)": {
        width: "20ch",
      },
    },
  },
  filterContainer: {
    border: `1px solid ${VERY_LIGHT_GREY} `,
    padding: "8px 16px ",
    borderRadius: "1px",
    gap: "12px",
    display: "flex",
    alignItems: "center",
  },
  filterText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: GONDOLA,
  },
  customFilterContainer: {
    top: "55px",
  },
  closeIcon: {
    fill: BROWN_HEADING,
    width: "24px",
    height: "24px",
    marginTop: "0.5rem",
  },
  closeButton: {
    cursor: "pointer",
  },
  closeIconContainer:{
    position:"absolute",
    top:"0px",
    right:"10px"
  },
  commonBtnStyle: {
    width: "120px",
    margin:"10px",
    fontWeight:"600",
  },
  itemsContainer:{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // marginRight: "30px",
  },
  deleteBtn: {
    backgroundColor: RED_RATING,
    "&:hover": {
      backgroundColor: LIGHT_RED,
    },
  },
};
