import { BROWN_HEADING } from "../../consts/colors";

export const styles={
    closeIconContainer: {
        display: "flex",
        justifyContent: "end",
        position: "relative",
      },
      closeButton: {
        padding: "0px",
        display: "flex",
        justifyContent: "end",
        position: "absolute",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "transparent",
        },

      },
      closeIcon: {
        fill: BROWN_HEADING,
        width: "24px",
        height: "24px",
        marginTop: "0.5rem",
        marginRight:"0.5rem",
        zIndex:"25"
      },
      formStyle:{
        maxWidth:"491px"
      }
}