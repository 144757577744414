import React, { Suspense } from "react";
import { useContext } from "react";
import { BrowserRouter as Router, Route, Routes,useLocation } from "react-router-dom";
import CustomLoader from "./components/CustomLoader/CustomLoader";
import DefaultRoute from "./routes/Components/DefaultRoute";
import Header from "./components/Header/Header";
import Login from "./containers/Login/Login";
import PrivateRoute from "./routes/Components/PrivateRoute";
import PublicRoute from "./routes/Components/PublicRoute";
import Sidebar from "./containers/Sidebar/Sidebar";
import { AuthContext } from "./globalContext/auth/authProvider";
import { UserContext } from "./globalContext/user/userProvider";
import navigations from "./routes/routeNames";
import UsersList from "./containers/UsersList/UsersList";
import { Roles } from "./consts/constants";

const ForgotPassword = React.lazy(() =>
  import("./containers/ForgotPassword/ForgotPassword")
);
const SaaSList = React.lazy(() => import("./containers/SaaSList/SaaSList"));
const CreateSaaS = React.lazy(() =>
  import("./containers/CreateSaaS/CreateSaaS")
);
const ResetPassword = React.lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);
const CreateUsers = React.lazy(() =>
  import("./containers/CreateUsers/CreateUsers")
);
const AccountList = React.lazy(() =>
  import("./containers/AccountList/AccountList")
);

const CreateAccount = React.lazy(() =>
  import("./containers/CreateAccount/CreateAccount")
);
const CoursesList = React.lazy(() =>
  import("./containers/Courses/CoursesList")
);
const CreateCourse = React.lazy(() =>
  import("./containers/CreateCourse/CreateCourse")
);
const Category = React.lazy(() => import("./containers/Category/Category"));
const DentistCategories = React.lazy(() =>
  import("./containers/DentistCategories/DentistCategoryies.jsx")
);

const DentistAccount=React.lazy(()=>
import("./containers/DentistAccount/DentistAccount")

)
const DentistSubCategory=React.lazy(()=>
import("./containers/DentistSubCategory/DentistSubCategory")
)
const DentistCourses=React.lazy(()=>
import("./containers/DentistCourses/DentistCourses"))

const CourseType=React.lazy(()=>
import("./containers/CourseType/CourseType"))
const CategoryType=React.lazy(()=>
import("./containers/CategoryType/CategoryType"))

function App() {
  const [authState] = useContext(AuthContext);
  const [userState] = useContext(UserContext);
  
  return (
    <Router>
      {!userState?.isGettingUserDetails && !!authState.auth && <Header />}
      <main className="main">
        {!!authState.auth &&
          !userState?.isGettingUserDetails &&
          authState?.role === Roles.admin && <Sidebar />}
        <Suspense fallback={<CustomLoader />}>

          <Routes>
            <Route path={navigations.ROOT} element={<DefaultRoute />} />
            <Route
              path={navigations.LOGIN}
              element={<PublicRoute component={<Login />} />}
            />
            <Route
              path={navigations.FORGOT_PASSWORD}
              element={<PublicRoute component={<ForgotPassword />} />}
            />
            <Route
              path={navigations.RESET_PASSWORD}
              element={<PublicRoute component={<ResetPassword />} />}
            />
            <Route
              path={navigations.ACCOUNTS}
              element={<PrivateRoute component={<AccountList />} />}
            />
            <Route
              path={navigations.SAAS}
              element={<PrivateRoute component={<SaaSList />} />}
            />
            <Route
              path={navigations.CREATE_SAAS}
              element={<PrivateRoute component={<CreateSaaS />} />}
            />
            <Route
              path={navigations.USERS}
              element={<PrivateRoute component={<UsersList />} />}
            />
            <Route
              path={navigations.CREATE_USER}
              element={<PrivateRoute component={<CreateUsers />} />}
            />
            <Route path={navigations.OUT_SOURCED} element={<DefaultRoute />} />
            <Route
              path={navigations.CREATE_ACCOUNT}
              element={<PrivateRoute component={<CreateAccount />} />}
            />
            <Route
              path={navigations.COURSES}
              element={<PrivateRoute component={<CoursesList />} />}
            />
            <Route
              path={navigations.COURSE_TYPE}
              element={<PrivateRoute component={<CourseType />} />}
            />
            <Route
              path={navigations.CREATE_COURSE}
              element={<PrivateRoute component={<CreateCourse />} />}
            />
            <Route
              path={navigations.CREATE_CATEGORY}
              element={<PrivateRoute component={<Category />} />}
            />
             <Route
              path={navigations.CATEGORY_TYPE}
              element={<PrivateRoute component={<CategoryType />} />}
            />
            <Route
              path={navigations.CATEGORIES}
              element={<PrivateRoute component={<DentistCategories />} />}
            />
            <Route
              path={navigations.CATEGORY}
              element={<PrivateRoute component={<DentistSubCategory/>} />}
            />
                <Route
              path={navigations.DENTIST_SETTING}
              element={<PrivateRoute component={<DentistAccount />} />}
            />
             <Route
              path={navigations.SUBCATEGORY}
              element={<PrivateRoute component={<DentistCourses />} />}
            />

          </Routes>
        </Suspense>
      </main>
    </Router>
  );
}

export default App;
