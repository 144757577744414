import { ECLIPSE, TRANSPARENT_GREY } from "../../consts/colors";

export const styles = {
  paginationContainerStyles: {
    paddingLeft: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: TRANSPARENT_GREY,
    height: "48px",
    borderRadious: "0px 0px 8px 8px",
    borderRadius:"8px"
  },
  pageActionContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  pageInfoStyles: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: ECLIPSE,
    padding: "15px 0px ",
    height: "100%",
  },
  rowsPerPageSelect: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: ECLIPSE,
    height: "38px",
  },
  pageNavStyles: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  currentPageStyles: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: ECLIPSE,
    padding: "0px  16px  0px  8px",
  },
  totalPagesStyles: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: ECLIPSE,
    height: "16px",
    width: "16px",
  },
  dropDownImage: {
    height: "16px",
    width: "16px",
  },
  arrow: {
    height: "48px",
    width: "48px", 
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
  },
  pagetext: {
    fontSize: "14px",
    fontWeight: 400,
    lineheight: "18px",
    letterSpacing: "0.1599999964237213px",
    textAlign: "left",
  },
  pageContainer: {
    padding: "15px 0px",
  },
  dividerStyle: {
    height: "48px",
  },
  pointer:{
    cursor:"pointer"
  }
};
