import { HOT_PURPLE } from "../../consts/colors";

export const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accessDeniedBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  lockIcon: {
    color: HOT_PURPLE,
    width: "40px",
    height: "40px",
  },
  accessDeniedMessage: {
    color: HOT_PURPLE,
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textAlign: "center",
  },
};
