import { useEffect, useRef, useState } from "react";

import useAxiosInstance from "../../../Axios/hooks/useAxiosInstance";
import { SUCCESS_STATUS } from "../../../api/statusCodes";
import { DELETE_RECORD_ERROR_MESSAGE } from "../../../consts/errorMessages";
import { API_STATUS } from "../../../consts/constants";
import axios from "axios";

const useDelete = () => {
  const [status, setStatus] = useState(API_STATUS.IDLE);
  const [error, setError] = useState("");
  const { axiosInstance } = useAxiosInstance();
  const cancelTokenSource = useRef();
  const handleDelete = async (url, callback) => {
    try {
      setStatus(API_STATUS.LOADING);
      error && setError("");
      const res = await axiosInstance.delete(`${url}`,{
        cancelToken:cancelTokenSource.current.token
      });
      if (res.status === SUCCESS_STATUS) {
        setStatus(API_STATUS.SUCCESS);
        callback();
        return;
      }
      setStatus(API_STATUS.ERROR);
      setError(DELETE_RECORD_ERROR_MESSAGE);
    } catch (err) {
      setStatus(API_STATUS.ERROR);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
      setError(DELETE_RECORD_ERROR_MESSAGE);
    }
  };

  const isDeleting = status === API_STATUS.LOADING;
  const deletedSuccess = status === API_STATUS.SUCCESS;
  const isDeletionError = status === API_STATUS.ERROR;

  useEffect(() => {
    cancelTokenSource.current=axios.CancelToken.source()
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Operation canceled by the user.');
      }
      setStatus(API_STATUS.IDLE);
      setError("");
    };
  }, []);

  return {
    error,
    isDeleting,
    deletedSuccess,
    isDeletionError,
    handleDelete,
    setError,
    setStatus,
    status,
  };
};
export default useDelete;
