import { createTheme } from "@mui/material";
import { fontFamily } from "./commonStyles";

const theme = createTheme({
  typography: {
    fontFamily: [fontFamily],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 610,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  }

});

export default theme;
