import { WHITE } from "../../consts/colors";

export const styles = {
  topRightBoxStyles: {
    position: "absolute",
    right: "10px",
    top: "5%",
    zIndex: 1,
  },
  iconStyles: {
    color: WHITE,
  },
  imageStyles: {
    maxWidth: "100%",
    objectFit: "contain",
  },
};
