import { createContext, useReducer } from "react";

import * as types from "./types";

const initialState = {
  isGettingUserDetails: false,
  userInfo: null,
  errorGettingUserInfo: null,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case types.GETTING_USER_INFO:
      return {
        ...state,
        ...{ isGettingUserDetails: action.payload },
      };
    case types.USER_PROFILE:
      return {
        ...state,
        ...{ userInfo: action.payload },
      };
    case types.ERROR_GETTING_USER_INFO:
      return {
        ...state,
        ...{ errorGettingUserInfo: action.payload },
      };
    default:
      return state;
  }
};

export const UserContext = createContext(initialState);

const UserProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={[userState, userDispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
