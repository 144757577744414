import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import ReactHlsPlayer from "react-hls-player";
import videoPlayer from "../../img/videoPlayer.svg";

import { styles } from "./VideoPlayerHslStyles";

const VideoPlayerHsl = ({
  src,
  handleClick,
  customVideoStyles,
  isControlDisable,
  showPlayIcon,
}) => {
  const playerRef = useRef();

  return (
    <Box sx={styles.container}>
      <ReactHlsPlayer
        playerRef={playerRef}
        controls={!isControlDisable}
        src={src}
        onClick={() => {
          handleClick && handleClick();
        }}
        style={{ ...customVideoStyles }}
      />
      {showPlayIcon && (
        <Typography
          component={"img"}
          src={videoPlayer}
          sx={styles.videoPlayer}
          onClick={() => {
            handleClick && handleClick();
          }}
        />
      )}
    </Box>
  );
};
export default VideoPlayerHsl;
