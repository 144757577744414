import * as types from "./types";

export const setGettingUserData = (payload) => {
  return {
    type: types.GETTING_USER_INFO,
    payload,
  };
};

export const setErrorGettingUserInfo = (payload) => {
  return {
    type: types.ERROR_GETTING_USER_INFO,
    payload,
  };
};

export const setUserData = (payload) => {
  return {
    type: types.USER_PROFILE,
    payload,
  };
};
