
import accountsImage from "../../img/accounts.svg";
import coursesImage from "../../img/courses.svg";
import saasIcon from "../../img/saas.svg";
import userIcon from "../../img/users.svg";
import navigations from "../../routes/routeNames";

export const sideBarIcons = [
  {
    img: accountsImage,
    navigateTo: navigations.ACCOUNTS,
    name: "Accounts",
    id: 1,
  },
  {
    img:coursesImage,
    navigateTo:navigations.COURSES,
    name:"Courses",
    id:2
  },
  {
    img: saasIcon,
    navigateTo: navigations.SAAS,
    name: "Saas",
    id: 3,
  },
  {
    img: userIcon,
    navigateTo: navigations.USERS,
    name: "Users",
    id: 4,
  },
  
];
