const navigations = {
  ROOT: "/",
  CREATE_SAAS: "/saas/create-saas",
  FORGOT_PASSWORD: "/forgot-password",
  LOGIN: "/login",
  SAAS: "/saas",
  OUT_SOURCED: "*",
  RESET_PASSWORD:'/forgot-password/:id',
  USERS: "/users",
  CREATE_USER:"/users/create",
  ACCOUNTS:"/accounts",
  CREATE_ACCOUNT:"/accounts/create",
  COURSES:"/courses",
  CREATE_COURSE:"/courses/create",
  CREATE_CATEGORY:"/category",
  SUBCATEGORY:"/dentist/category/:categoryId/subcategory/:id",
  CATEGORIES:"/dentist/category",
 CATEGORY:"/dentist/category/:id",
 DENTIST_SETTING:"/dentist/account",
 SUBCAT:"/dentist/category",
 COURSE_TYPE:"/courses/type",
 CATEGORY_TYPE:"/category/type"
};

export default navigations; 
