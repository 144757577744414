import { APP_PRIMARY_THEME_COLOR,  JON } from "../../consts/colors";

export const styles = {
  autoCompleteContainer: {
    height: "max-content",
    width: "80px",
    border: `1px solid ${APP_PRIMARY_THEME_COLOR}`,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listPages: {
    color: JON,
    padding: "12px 16px 16px 16px",
    height: "48px",
    alignSelf: "center",
  },
  textField: {
    borderRadius: "6px",
    height: "38px",
    "& fieldset": { border: "none" },
    fontSize: "14px",
    color: JON,
    "& .MuiOutlinedInput-root": {
      padding: "0px 30px",
    },
  },
};
