import { createContext, useReducer } from 'react'
import * as types from './types'
const initialState={
    categories:[],
    errorMessage:""
}

const categoriesReducer=(state,action)=>{
    switch (action.type) {
        case types.SET_CATEGORY:
        return {
       categories:action.payload,
       errorMessage:""
        }
        case types.SET_ERROR:
        return {
            categories:[],
            errorMessage:action.payload
        }
        default:
            return state;          
}
}
export const CategoriesContext=createContext(initialState)
const CategoriesProvider= ({ children }) => {
    const [categoriesInfo, categoriesDispatch] = useReducer(categoriesReducer, initialState);
    return (
      <CategoriesContext.Provider value={{categoriesInfo, categoriesDispatch}}>
        {children}
      </CategoriesContext.Provider>
    );
  };
  export default CategoriesProvider