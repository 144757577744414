import { GREY95, GONDOLA } from "../../consts/colors";

export const styles = {
  filterTitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: GONDOLA,
  },
  accordionStyles: {
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${GREY95}`,
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionDetails-root": {
      paddingTop: 0,
    },
  },
  accordionHeader: {
    flexDirection: "row-reverse",
    alignItems: "center",
    display: "flex",
    gap: "8px",
    padding: "8px",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    "&.Mui-expanded": {
      minHeight: "0px",
    },
  },
};
