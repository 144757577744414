import { GONDOLA, HOT_PURPLE } from "../../consts/colors";

export const styles = {
  filterTitleContainer: {
    padding: "12px 16px 16px 16px",
  },
  filterTitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: GONDOLA,
  },
  listContainer: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    maxHeight: "180px",
    overflowY: "auto",
    padding: "8px 16px 8px 44px",
    display: "flex",
    flexDirection:"column",
    gap: "8px",
    "&.MuiFormGroup-root":{
      flexWrap:"nowrap"
    }
  },
  radioBtnStyles: {
    "&.Mui-checked": {
      color: HOT_PURPLE,
    },
  },
};
