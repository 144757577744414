import {
  ALERT_USER,
  GONDOLA,
  HOT_PURPLE,
  MIDNIGHT_EXPRESS,
  PLATINUM_SILVER,
  WHITE,
} from "../../consts/colors";

export const styles = {
  container: {
    display: "flex",
  },
  smallFilter:{
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "8px 16px ",
    borderRadius: "6px",
    border: `1px solid ${PLATINUM_SILVER}`,
    maxWidth: "200px",
    height: "46px",
    cursor: "pointer",
    backgroundColor: WHITE,
    "&:active": {
      border: `1px solid ${HOT_PURPLE}`,
    },
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  activeFilter: {
    border: `1px solid ${HOT_PURPLE}`,
  },
  filterIcon: {
    width: "14px",
    height: "14px",
  },
  filterHeadingContainer: {
    display: "flex",
    gap: "5px",
  },
  filterText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    color: GONDOLA,
  },
  filterCountContainer: {
    backgroundColor: ALERT_USER,
    height: "20px",
    width: "20px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  filterCount: {
    color: WHITE,
  },
  filterBoxContainer: {
    maxWidth: "280px",
    borderRadius: "8px",
    padding: "12px 0px 12px 0px",
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    border: `1px solid ${PLATINUM_SILVER}`,
    backgroundColor: WHITE,
    boxShadow: `0px 1px 2px 0px ${MIDNIGHT_EXPRESS}`,
    position: "absolute",
    width: "250px",
    right: 0,
    top: "100%",
  },

  
 
  
};
