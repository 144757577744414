import { styles } from "./EditUserDetailStyle";

export const columns = [
  {
    label: "FirstName*",
    name: "first_name",
    component: "TextField",
    type: "text",
    required: true,
    isTextTransform: true,
  },
  {
    label: "LastName*",
    name: "last_name",
    component: "TextField",
    type: "text",
    required: true,
    isTextTransform: true,
  },
  {
    label: "Email*",
    name: "email",
    component: "TextField",
    type: "email",
    required: true,
  },
  {
    label: "Password",
    name: "password",
    component: "TextField",
    type: "password",
    autoCompleteOff: true,
    hasEyeIcon: true,
  },
  {
    label: "Phone*",
    name: "phone_number",
    component: "TextField",
    required: true,
  },
];

export const checkBoxGroup = [
  {
    name: "account",
    label: "Account",
    component: "CheckBox",
    noLabel: true,
  },
  {
    name: "user",
    label: "User",
    component: "CheckBox",
    noLabel: true,
  },
  {
    name: "deleteUser",
    label: "Delete User",
    component: "CheckBox",
    noLabel: true,
  },
  {
    name: "deleteSaaS",
    label: "Delete Saas",
    component: "CheckBox",
    noLabel: true,
    customLabelStyles: styles.orangeCheckBoxLabel,
  },
  {
    name: "categories",
    label: "Categories",
    component: "CheckBox",
    noLabel: true,
  },
  {
    name: "deleteCategories",
    label: "Delete Categories",
    component: "CheckBox",
    customLabelStyles: styles.orangeCheckBoxLabel,
    noLabel: true,
  },
  {
    name: "course",
    label: "Course",
    component: "CheckBox",
    noLabel: true,
  },
  {
    name: "deleteCourse",
    label: "Delete Course",
    component: "CheckBox",
    customLabelStyles: styles.orangeCheckBoxLabel,
    noLabel: true,
  },
  {
    name: "deleteAccount",
    label: "Delete Account",
    component: "CheckBox",
    noLabel: true,
    customLabelStyles: styles.orangeCheckBoxLabel,
  },
  {
    name: "saasAdmin",
    label: "Saas",
    component: "CheckBox",
    noLabel: true,
  },
];

export const formFields = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  phone_number: "",
  saasAdmin: false,
  deleteSaaS: false,
  account: false,
  deleteAccount: false,
  user: false,
  deleteUser: false,
  deleteCourse: false,
  course: false,
  categories: false,
  deleteCategories: false,
};
export const inputs = [
  "first_name",
  "last_name",
  "email",
  "password",
  "phone_number",
];
