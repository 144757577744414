import { ALERT_USER, DIM_GREY, ECLIPSE } from "../../consts/colors";

export const styles = {
  label: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "5px",
    paddingLeft: "0",
    color: ECLIPSE,
  },
  errorInLabel: {
    color: ALERT_USER,
  },
  asteriskStyles: {
    color: DIM_GREY,
  },
  asteriskRedStyles: {
    color: ALERT_USER,
  },
};
