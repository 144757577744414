import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SingleFilterList from "../SingleFilterList/SingleFilterList";
import { styles } from "./MultipleFilterListStyles";

const MultipleFilterList = ({
  filters,
  includeTitle = false,
  selectedValue,
  setFilterListVisible,
  isSmallFilter
}) => {
  return filters.map((filter, index) => (
    <Accordion key={index} defaultExpanded sx={{...styles.accordionStyles,...(isSmallFilter && {maxHeight:"220px"}) }}>
      <AccordionSummary
        sx={styles.accordionHeader}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={styles.filterTitle}>{filter.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
          <SingleFilterList
            filter={filter}
            selectedValue={selectedValue}
            includeTitle={includeTitle}
            setFilterListVisible={setFilterListVisible}
          />
      </AccordionDetails>
    </Accordion>
  ));
};

MultipleFilterList.propTypes = {
  filters: PropTypes.array,
  includeTitle: PropTypes.bool,
  selectedValue: PropTypes.object,
  setFilterListVisible: PropTypes.func
};

export default MultipleFilterList;
