import {
  BRIGHT_RED,
  LIGHT_GREEN,
  MISTY_ROSE,
  VIBRANT_GREEN,
} from "../../consts/colors";

const styles = {
  toastMessage: {
    justifyContent: "center",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    width: "100%",
  },
  commonToastMessage: {
    width: "100%",
    justifyContent: "center",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
  },
  toastSuccessMessage: {
    backgroundColor: LIGHT_GREEN,
    color: VIBRANT_GREEN,
  },
  toastErrorMessage: {
    backgroundColor: MISTY_ROSE,
    color: BRIGHT_RED,
  },
};

export default styles;
