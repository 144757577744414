import { FADED_GREY, GREY77, RED_RATING, WHITE } from "../../consts/colors";

const styles = {
  cropParentContainer: {
    padding: '16px'
  },
  cropperContainer: {
    position: "relative",
    overflowY: "auto",
    padding: "16px 24px",
    borderTop: `1px solid ${FADED_GREY}`,
    borderBottom: `1px solid ${FADED_GREY}`,
    backgroundColor: GREY77,
    height: "35vh",
    borderRadius: "8px",
  },
  actionBtnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "8px",
    marginTop: "32px",
  },
  customContainerStyle: {
    wordBreak: "break-word",
    paddingTop: "8px",
  },
  customTextStyle: {
    fontSize: "14px",
    color: RED_RATING,
  },
  buttonStyle: {
    width: "80px",
  },
  additionalStyles: {
    cursor: "not-allowed",
  },
  cropAreaStyle: {
    border: `2px solid ${WHITE}`,
  },
};

export default styles;
