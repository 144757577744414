import React from "react";
import { Navigate } from "react-router-dom";
import getRole from "../../auth/getRole";

import getToken from "../../auth/getToken";
import { Roles } from "../../consts/constants";
import navigations from "../routeNames";

const DefaultRoute = () => {
  const tokenCheck = getToken();
  const role = getRole();
  if (tokenCheck) {
    if (role === Roles.dentist) {
      return <Navigate to={navigations.CATEGORIES} />;
    }

    return <Navigate to={navigations.ACCOUNTS} />;
  }
  return <Navigate to={navigations.LOGIN} />;
};

export default DefaultRoute;
