import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";

import CustomBanner from "../CustomBanner/CustomBanner";
import EditViewForm from "../EditViewForm/EditViewForm";
import { UserContext } from "../../globalContext/user/userProvider";
import useGettingUserInfo from "../../api/user/useGettingUserInfo";
import useUpdateData from "../../utils/apiService/Hooks/useUpdateData";
import { columns, formFields, inputs } from "./EditUserDetailsConstant";
import { UPDATE_USER } from "../../api/apiEndpoints";
import { SHOW_SAVE_DATA_TOAST_DURATION } from "../../consts/toastShowDuration";
import { commonStyles } from "../../assests/commonStyles";
import { styles } from "./EditUserDetailStyle";
import { checkBoxGroup } from "../../containers/CreateUsers/CreateUserConstant";
import { permissionNames } from "../../routes/authorisation";
import CustomCheckBoxGroup from "../CustomCheckBoxGroup/CustomCheckBoxGroup";
import usePhoneZipValidation from "../../CustomHooks/usePhoneZipValidation";
import useValidateEmail from "../../CustomHooks/useValidateEmail";
import useValidatePassword from "../../utils/apiService/Hooks/useValidatePassword";
import SearchAndFilter from "../SearchAndFilter/SearchAndFilter";
import { isfieldMissing } from "../../utils/FieldMissing";

const EditUserDetails = ({
  data,
  closeUserDetailsModal,
  setUserList,
  setUserSuccess,
  loader,
  setEditModal,
}) => {
  const formattedFormData = () => {
    const { access, ...otherFormData } = data;
    const newObj = { ...formFields, ...otherFormData };
    if (access != null) {
      access.split(",").map((item) => {
        if (!!item?.trim()) {
          newObj[item] = true;
        }
        return item;
      });
    }
    return newObj;
  };
  const ApiData = data;
  const [formData, setFormData] = useState(formattedFormData());
  const [userState] = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const { getUserDetails } = useGettingUserInfo();

  const { error, loading, handleUpdate, setError } = useUpdateData();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { passwordCheck, passwordErrorMessage } = useValidatePassword();

  const anyErrorExist = () => {
    if (!!inValidEmailMessage || !!invalidPhoneNumberMessage || !!passwordErrorMessage) {
      return true;
    }
    return false;
  };

  const isEditBtnDisabled = () => {
    const initialData = formattedFormData();

    for (let keys in formData) {
      if (formData[keys] !== initialData[keys]) {
        return false;
      }
    }

    return true;
  };

  const formatData = () => {
    const obj = {};
    for (let keys in formData) {
      if (inputs.includes(keys)) {
        if (keys === "password" && formData[keys] === "") {
          continue;
        }
        obj[keys] = formData[keys];
      } else if (formData[keys] === true) {
        if (obj["access"] !== undefined) {
          obj["access"] = obj["access"] + keys + ",";
        } else {
          obj["access"] = keys + ",";
        }
      }
    }
    if (obj["access"]?.length > 0) {
      obj["access"] = obj["access"].substring(0, obj["access"].length - 1);
    }

    return obj;
  };

  const { invalidPhoneNumberMessage, validate: validatePhoneZip } =
    usePhoneZipValidation();

  const { validateEmail, inValidEmailMessage } = useValidateEmail({});

  const updatedColumns = () => {
    let newColumns = [...columns];
    newColumns = newColumns.map((item) => {
      if (item.name === "phone_number") {
        item.validate = () => {
          validatePhoneZip({
            phoneNumber: formData[item.name],
            validationKey: "phone_number",
          });
        };
      } else if (item.name === "email") {
        item.validate = () => {
          validateEmail(formData["email"], ApiData["email"]);
        };
      } else if (item?.name === "password") {
        item.validate = () => {
          passwordCheck(formData["password"], true);
        };
      }
      return item;
    });
    const newCheckboxColumns = [...checkBoxGroup];
    const updateCheckBoxColumns = newCheckboxColumns.map((item) => {
      if (item.name === permissionNames.saas) {
        item.handleChange = (e) => {
          if (e.target.checked === false) {
            setFormData((prevData) => ({
              ...prevData,
              [item.name]: e.target.checked,
              [permissionNames.deleteSaaS]: false,
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              [item.name]: e.target.checked,
            }));
          }
        };
      } else if (item.name === permissionNames.accounts) {
        item.handleChange = (e) => {
          if (e.target.checked === false) {
            setFormData((prevData) => ({
              ...prevData,
              [item.name]: e.target.checked,
              [permissionNames.deleteAccount]: false,
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              [item.name]: e.target.checked,
            }));
          }
        };
      }
      return item;
    });

    const obj = {
      component: "Custom",
      customJsx: (
        <Box>
          <Typography sx={styles.label}>Admin Access Type</Typography>
          <CustomCheckBoxGroup
            columns={updateCheckBoxColumns}
            setFormData={setFormData}
            formData={formData}
          />
        </Box>
      ),
      isFull: true,
    };
    newColumns.push(obj);
    return newColumns;
  };

  const saveData = (dataObj,userId) => {
    const { password, ...otherFields } = dataObj;
    setUserList((prevDetails) => {
      const users = [...prevDetails.data.data.users];
      const updatedUsers = users.map((item) => {
        if (item.id !== userId) {
          return item;
        }
        return { ...otherFields, id: userId };
      });
      const updatedState = {
        ...prevDetails,
      };
      updatedState.data.data.users = updatedUsers;
      return updatedState;
    });

    if (userState?.userInfo?.data?.data?.id === data?.id) {
      getUserDetails();
    }
  };
  const submit = () => {
    const { id, ...otherData } = formData;
    const obj = formatData(otherData);
    handleUpdate({
      url: `${UPDATE_USER}/${data?.id}`,
      errorMessage:
        "Unable to update user details at the moment ,Please try again later.",
      payload: obj,
      successCallback: () => {
        setUserSuccess(true);
        saveData(obj,data?.id);
        closeUserDetailsModal();
      },
    });
  };

  return (
    <Box customStyles={{ ...commonStyles.tableContainer }}>
      <SearchAndFilter
        headerTitle={"Edit User Details"}
        hideSearchBox
        customToolbar={styles.customToolBarStyles}
        isBackButton
        onBackButtonClick={() => {
          setEditModal(false);
        }}
        isCloseButton
        onCloseButton={() => {
          closeUserDetailsModal();
          setEditModal(false);
        }}
        buttonArray={[
          {
            type: "submit",
            id: "user",
            handleClick: () => {
              submit();
            },
            loader: loading,
            text: "Save",
            isDisabled: isEditBtnDisabled() || anyErrorExist() || isfieldMissing({columns:updatedColumns(),formData} ),
          },
        ]}
      />
      <EditViewForm
        isEditable={true}
        isThreeColumns
        columns={updatedColumns()}
        formData={formData}
        setFormData={setFormData}
        onSubmitClick={submit}
        isloading={loading}
        isDisabled={isEditBtnDisabled() || anyErrorExist()}
        showPassword={showPassword}
        handleTogglePasswordVisibility={handleTogglePasswordVisibility}
        onClose={() => setError("")}
        errorForm={{
          phone_number: invalidPhoneNumberMessage,
          email: inValidEmailMessage,
          password: passwordErrorMessage,
        }}
        noSubmitButton
      />
      {error && (
        <Box sx={styles.toastContainer}>
          <CustomBanner
            openSnackbar={!!error}
            hideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
            snackbarMessage={error}
            severity="error"
            autoHideDuration={1000}
            onClose={() => setError("")}
          />
        </Box>
      )}
    </Box>
  );
};

export default EditUserDetails;
