import {
  APP_PRIMARY_THEME_COLOR,
  BLACK,
  DARK_GREY,
  LIGHT_GREY_SHADE,
  TEXT_GREY,
} from "../../consts/colors";

const styles = {
  contentContainerStyle: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    height: "120px",
    border: `1px solid ${DARK_GREY}`,
    borderRadius: "8px",
    borderStyle: "dashed",
    justifyContent: "center",
    backgroundColor: LIGHT_GREY_SHADE,
    alignItems: "center",
    width: "120px",
    cursor: "pointer",
    flexShrink: 0,
    position:"relative",
    zIndex:10
  },
  loaderBox: {
    position: "relative",
  },
  percentageBox: {
    position: "absolute",
    left: "25%",
    top: "25%"
  },
  percentageBoxTwoDigitNumber: {
    left: "20%",
  },
  percentageText: {
    fontSize: "12px",
    lineHeight: "16px",
    color: TEXT_GREY,
  },
  textContainer: {
    flexDirection: "row",
    marginTop: "24px",
    marginBottom: "10px",
  },
  textStyle: {
    fontSize: "16px",
    lineHeight: "24px",
    color: BLACK,
    marginRight: "4px",
  },
  browseStyle: {
    fontSize: "px",
    lineHeight: "24px",
    color: APP_PRIMARY_THEME_COLOR,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  uploadIcon: {
    width: "30px",
    height: "30px",
  },
  infoStyle: {
    textAlign: "center",
    color: BLACK,
    lineHeight: "18px",
    fontSize: "12px",
  },
  hideRawInputField: {
      border: "none",
      padding: 0,
      display: "none",
  },
  error: {
    marginTop: "10px",
    textAlign: "center",
  },
  spinnerStyle: {
    height: "none"
  },
};

export default styles;
