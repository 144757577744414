import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import { styles } from "./LabelStyles";

const Label = React.forwardRef(
  (
    { children, isError, labelInputProp, showAsterisk, showAsteriskInRed },
    ref
  ) => {
    return (
      <Typography
        ref={ref}
        sx={{
          ...styles.label,
          ...labelInputProp,
          ...(isError ? styles.errorInLabel : {}),
        }}
      >
        {children}
        {showAsterisk && (
          <Typography
            component="span"
            sx={{
              ...styles.asteriskStyles,
              ...(showAsteriskInRed ? styles.asteriskRedStyles : {}),
            }}
          >
            &nbsp;*
          </Typography>
        )}
      </Typography>
    );
  }
);

Label.propTypes = {
  children: PropTypes.node,
  isError: PropTypes.bool,
  labelInputProp: PropTypes.object,
  showAsterisk: PropTypes.bool,
  showAsteriskInRed: PropTypes.bool,
};

export default Label;
