import {
  ALERT_USER,
  BORDER_GREY,
  FORM_INPUBOX_COLOR,
  PASSWORD_MATCHED,
} from "../../consts/colors";

export const styles = {
  inputBase: {
    border: `1px solid ${BORDER_GREY}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    height: "2.5rem",
    width: "90%",
    color: FORM_INPUBOX_COLOR,
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    "@media (max-width : 600px)": {
      width: "100%",
    },
  },
  passwordVisibilityIcon: {
    width: "21px",
    height: "21px",
  },
  clearIconStyles: {
    color: BORDER_GREY,
  },
  checkEndIcon: {
    padding: "8px",
  },
  greenIcon: {
    fill: PASSWORD_MATCHED,
    padding: "8px",
  },
  redIcon: {
    fill: ALERT_USER,
    padding: "8px",
  },
  errorStyles:{
    color:ALERT_USER,fontSize:"12px"
  },
  
};
