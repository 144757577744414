import { useEffect, useRef, useState } from "react";
import { API_STATUS } from "../../../consts/constants";
import {
  DATA_SAVED_SUCCESSFULLY,
  SUCCESS_STATUS,
} from "../../../api/statusCodes";
import useAxiosInstance from "../../../Axios/hooks/useAxiosInstance";
import axios from "axios";

const usePostData = () => {
  const [status, setStatus] = useState(API_STATUS.IDLE);
  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const cancelTokenSource = useRef();


  const {
    axiosInstance
   }=useAxiosInstance();

  const handlePost = async ({
    url,
    payload,
    errorMessage = "Unable to create record at the moment ,Please try again later.",
    successCallback = () => {},
  }) => {
    try {
      setloading(true);
      setStatus(API_STATUS.LOADING);
      error && setError("");
      const res = await axiosInstance({
        method: 'POST',
        url: `${url}`,
        data: payload,
        cancelToken: cancelTokenSource.current.token
      });      if (res.status === SUCCESS_STATUS || DATA_SAVED_SUCCESSFULLY) {
        setStatus(API_STATUS.SUCCESS);
        setloading(false);
        setData(res.data);
        successCallback && successCallback(res.data);
        return;
      }
      setError(errorMessage);
      setloading(false);
    } catch (err) {
      setloading(false);
      setStatus(API_STATUS.ERROR);
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
        return;
      }
    }
  };

  useEffect(() => {
    cancelTokenSource.current=axios.CancelToken.source()
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Operation canceled by the user.');
      }
      setData(null);
      setError("");
      setStatus(API_STATUS.IDLE);
      setloading(false);
    };
  }, []);

  return {
    error,
    loading,
    status,
    data,
    handlePost,
    setError,
    setStatus,
  };
};

export default usePostData;
