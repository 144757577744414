import React from "react";
import { Box, Divider, MenuItem, Select, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import PageInput from "../PageInput/PageInput";
import { ROW_PER_PAGE_OPTIONS } from "../../consts/constants";
import { styles } from "./Pagination.style";

const Pagination = ({ pagesInfo, handlePageChange }) => {
  const { currentPage, total, rowsperPage, handleRowPerPage, hideRowPerPage = false } = pagesInfo;
  const first = total > 0 ? (currentPage - 1) * rowsperPage + 1 : 0;
  const last = Math.min(currentPage * rowsperPage, total);
  const totalPage = total > 0 ? Math.ceil(total / rowsperPage) : 0;

  const handleRowsPerPage = (e) => {
    const rowCount = e.target.value
    handleRowPerPage(rowCount)
  }

  return (
    <Box sx={styles.paginationContainerStyles}>
      <Box sx={styles.pageInfoStyles}>
        {`${first} - ${last} of ${total} entries`}
      </Box>
      <Box sx={styles.pageActionContainer}>
        {!hideRowPerPage && (
          <Box sx={styles.pageNavStyles}>
            <Typography sx={styles.pagetext}>Rows per page:</Typography>
            <Select
              sx={styles.rowsPerPageSelect}
              value={rowsperPage}
              onChange={handleRowsPerPage}
              displayEmpty
              inputProps={{ "aria-label": "Rows per page" }}
            >
              {ROW_PER_PAGE_OPTIONS.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Box sx={styles.pageNavStyles}>
          <Box sx={styles.currentPageStyles}>
            <Box sx={styles.pageContainer}>
              <Box sx={styles.pagetext}>{"Page"}</Box>
            </Box>
            <PageInput
              totalpage={+totalPage}
              currentPage={+currentPage}
              handlePageChange={handlePageChange}
            />
            <Box sx={styles.pageContainer}>
              <Typography sx={styles.pagetext}>
                {" "}
                {`of ${totalPage}  pages`}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.arrowContainer}>
            <Divider orientation="vertical" sx={styles.dividerStyle} />
            <ArrowLeftIcon
              sx={{...styles.arrow,...(currentPage-1>0 && styles.pointer)}}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            <Divider orientation="vertical" sx={styles.dividerStyle} />
            <ArrowRightIcon
              sx={{...styles.arrow,...(currentPage+1<=totalPage && styles.pointer)}}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Pagination;
