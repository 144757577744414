import React, { useEffect, useMemo, useState } from "react";
import Cropper from "react-easy-crop";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import CustomButton from "../CustomButton/CustomButton";
import CustomModal from "../CustomModal/CustomModal";
import ZoomSliderWithInfo from "../ZoomSliderWithInfo/ZoomSliderWithInfo";
import getCroppedImg from "../../utils/cropImage";
import { getImageSource } from "../../utils/getImageSource";
import { ZOOM_CONSTANT } from "../../consts/constants";
import styles from "./CropAndRotateImageStyles";

const CropAndRotateImage = ({
  file,
  errorWhileUpload,
  handleFileUpload,
  heading,
  isLoading,
  initiateFileUpload,
  onClose,
  onSuccess,
  photoURL,
  setFile,
  setOpenCropView,
  shouldOpenInModal,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(ZOOM_CONSTANT.MIN_ZOOM);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCroppingImage, setIsCroppingImage] = useState(false);
  const [isErrorCroppingImage, setIsErrorCroppingImage] = useState(false);

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  const uploadImageToServer = ({ uploadedFile }) => {
    handleFileUpload({
      file: uploadedFile,
      successCallback: (file) => {
        onSuccess(file);
      },
    });
  };

  const cropImage = async () => {
    try {
      setIsCroppingImage(true);
      isErrorCroppingImage && setIsErrorCroppingImage(false);
      const { file } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      const uploadedFile = new File([file], "cropped.jpeg", { type: "image/jpeg" });
      setIsCroppingImage(false);
      setOpenCropView(false);
      initiateFileUpload({
        onLoad: () => uploadImageToServer({ uploadedFile: uploadedFile }),
        uploadedFile: file,
      });
    } catch (error) {
      setIsErrorCroppingImage(true);
    }
  };

  const cancelCropHandler = () => {
    setOpenCropView(false);
    setFile(null);
    onClose && onClose();
  };

  const resetStates = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setCroppedAreaPixels(null);
    setIsCroppingImage(false);
    setIsErrorCroppingImage(false);
  };

  const fileName = useMemo(() => {
    return getImageSource(file)
  },[file])

  const renderModalContent = () => {
    return (
      <Box sx={styles.cropParentContainer}>
        <Box sx={styles.cropperContainer}>
          <Cropper
            aspect={1}
            crop={crop}
            cropShape="round"
            image={fileName}
            onCropChange={setCrop}
            onCropComplete={(croppedArea, croppedAreaPixels) =>
              setCroppedAreaPixels(croppedAreaPixels)
            }
            onRotationChange={(val)=> setRotation(val)}
            onZoomChange={(val) => setZoom(val)}
            rotation={rotation}
            zoom={zoom}
            showGrid={false}
            zoomSpeed={2}
            style={{ cropAreaStyle: styles.cropAreaStyle }}
          />
        </Box>
        <ZoomSliderWithInfo {...{ setZoom, zoom, setRotation }} />
        {!!errorWhileUpload && (
          <Typography
            fontWeight="600"
            sx={{ ...styles.customTextStyle, ...styles.customContainerStyle }}
          >
            {errorWhileUpload}
          </Typography>
        )}
        <Box sx={styles.actionBtnContainer}>
          <CustomButton
            onClick={
              isCroppingImage || isLoading ? () => {} : ()=>cancelCropHandler()
            }
            style={{
              ...styles.buttonStyle,
              ...(isCroppingImage || isLoading ? styles.additionalStyles : {}),
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            isLoading={isCroppingImage || isLoading}
            onClick={()=>cropImage()}
            style={styles.buttonStyle}
            withGreenBackground
          >
            <Typography>Save</Typography>
          </CustomButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {shouldOpenInModal ? (
        <CustomModal
          openModal={shouldOpenInModal}
          closeModalHandler={() => cancelCropHandler()}
          maxWidth="sm"
          fullWidth
          {...{ heading }}
        >
          {renderModalContent()}
        </CustomModal>
      ) : (
        renderModalContent()
      )}
    </>
  );
};

CropAndRotateImage.defaultProps = {
  file: null,
  errorWhileUpload: "",
  handleFileUpload: () => {},
  heading: "Edit Picture",
  isLoading: false,
  initiateFileUpload: () => {},
  onClose: () => {},
  onSuccess: () => {},
  photoURL: "",
  setFile: () => {},
  setOpenCropView: () => {},
  shouldOpenInModal: true,
};

CropAndRotateImage.propTypes = {
  file: PropTypes.object,
  errorWhileUpload: PropTypes.string,
  handleFileUpload: PropTypes.func,
  heading: PropTypes.string,
  isLoading: PropTypes.bool,
  initiateFileUpload: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  photoURL: PropTypes.string,
  setFile: PropTypes.func,
  setOpenCropView: PropTypes.func,
  setPhotoURL: PropTypes.func,
  shouldOpenInModal: PropTypes.bool,
};

export default CropAndRotateImage;
