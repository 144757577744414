import { useContext } from "react";

import { UserContext } from "../globalContext/user/userProvider";
import { permissionNames } from "../routes/authorisation";

const useAuthorisation = () => {
  const [userState] = useContext(UserContext);

  const userAccountPermissions =
    userState?.userInfo?.data?.data?.access;

  const isDeletePracticesAccess = userAccountPermissions?.includes(
    permissionNames.deletePractices
  );
  const isDeleteOfficesAccess = userAccountPermissions?.includes(
    permissionNames.deleteOffices
  );
  const isDeleteSaaSAccess = userAccountPermissions?.includes(
    permissionNames.deleteSaaS
  );
  const isDeleteUsersAccess = userAccountPermissions?.includes(
    permissionNames.deleteUsers
  );
  const isDeleteAccountAccess=userAccountPermissions?.includes(
    permissionNames.deleteAccount
  )

  return {
    isDeletePracticesAccess,
    isDeleteOfficesAccess,
    isDeleteSaaSAccess,
    isDeleteUsersAccess,
    isDeleteAccountAccess
  };
};

export default useAuthorisation;
