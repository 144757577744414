
import { createContext, useReducer } from "react";

const initialState = {
    deleteSuccess: "idle"
};

export const types = {
    deleteSuccess: 'DELETE_SUCCESS',
    deleteFail: 'DELETE_FAIL',
    deleteIdle:"DELETE_IDLE"
};

const DeleteResultReducer = (state, action) => {
    switch (action.type) {
        case types.deleteSuccess:
            return {
                deleteSuccess: "success"
            };
        case types.deleteFail:
            return {
                deleteSuccess: "fail"
            };
            case types.deleteIdle:
            return {
                deleteSuccess: "idle"
            };
        default:
            return state;
    }
};

export const DeleteResultContext = createContext(initialState);

const DeleteResultProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DeleteResultReducer, initialState);

    return (
        <DeleteResultContext.Provider value={[state, dispatch]}>
            {children}
        </DeleteResultContext.Provider>
    );
};

export default DeleteResultProvider