import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import CustomButton from "../CustomButton/CustomButton";
import MultipleFilterList from "../MultipleFilterList/MultipleFilterList";
import SingleFilterList from "../SingleFilterList/SingleFilterList";
import useOutsideClick from "../../CustomHooks/useOutsideClick";
import filterIcon from "../../img/filterIcon.svg";
import { styles } from "./FilterMenuStyles";
 
const FilterMenu = ({
  customFilterContainerStyle,
  defaultSelected,
  filterOptions,
  selectedValue,
  isSmallFilter
}) => {
  const filterMenuRef = useRef(null);
  const [filterListVisible, setFilterListVisible] = useState(false);
  const isMultiple = filterOptions?.length > 1;

  useOutsideClick(filterMenuRef, () => setFilterListVisible(false));

  const handleFilterButtonClick = () => {
    setFilterListVisible((prev) => !prev);
  };

  const countSelectedFilters = () => {
    return Object.values(selectedValue).filter((value) => value !== "").length;
  };

  const getFilterTitle = () => {
    const filter = filterOptions[0];
    if (filterOptions.length === 1) {
      const selectedOption = filter.content.find(
        (option) => option.value === selectedValue[filter.filterKey]
      );
      if (
        selectedOption &&
        defaultSelected !== selectedValue[filter.filterKey]
      ) {
        return selectedOption.label;
      }
    }
    return "Filters";
  };

  const selectedFilterCount = countSelectedFilters();

  return (
    <Box sx={styles.container}>
      <Box position="relative" ref={filterMenuRef}>
        <CustomButton
          classes={{
            ...styles.filterContainer,
            ...(filterListVisible ? styles.activeFilter : {}),
          }}
          onClick={() => handleFilterButtonClick()}
        >
          <Typography component={"img"} src={filterIcon} />
          <Box sx={styles.filterHeadingContainer}>
            <Typography sx={styles.filterText}>{getFilterTitle()}</Typography>
            {isMultiple && !!selectedFilterCount && (
              <Box sx={styles.filterCountContainer}>
                <Typography
                  sx={{ ...styles.filterText, ...styles.filterCount }}
                >
                  {selectedFilterCount}
                </Typography>
              </Box>
            )}
          </Box>
        </CustomButton>
        {filterListVisible && (
          <Box
            sx={{ ...styles.filterBoxContainer, ...customFilterContainerStyle,...(isSmallFilter && styles.smallFilter) }}
          >
            {isMultiple ? (
              <MultipleFilterList
                filters={filterOptions}
                selectedValue={selectedValue}
                setFilterListVisible={setFilterListVisible}
                {...(isSmallFilter ? { isSmallFilter } : {})}
              />
            ) : (
              <SingleFilterList
              {...{
                filter: filterOptions[0],
                selectedValue,
                setFilterListVisible,
              }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

FilterMenu.propTypes = {
  customFilterContainerStyle: PropTypes.object,
  defaultSelected: PropTypes.string,
  filterOptions: PropTypes.array,
  selectedValue: PropTypes.object,
  isSmallFilter:PropTypes.bool
};

export default FilterMenu;
