import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import CustomLoader from "../CustomLoader/CustomLoader";
import { commonStyles } from "../../assests/commonStyles";
import styles from "./DragAndDropCardStyles";

const DragAndDropCard = ({
  errorMessage,
  fileInputRef,
  fileUploadHandler,
  handleDragOver,
  handleDrop,
  handleUploadClick,
  isDocumentUpload,
  isVideoUpload,
  isLoading,
  uploadPercentage,
  customContentContainerStyle,
  isVideoPdfUpload
}) => {
  const webProps = {
    onDragOver: handleDragOver,
    onDrop: handleDrop,
  };

  let additionalStyle = {};
  additionalStyle = {
    ...styles.percentageBox,
    ...(Math.round(uploadPercentage) > 9
      ? styles.percentageBoxTwoDigitNumber
      : {}),
  };
  const getAcceptedFiles = () => {
    if (isDocumentUpload) {
      return ".pdf, image/png, image/jpeg, image/svg, image/eps";
    }
    if (isVideoUpload) {
      return ".mp4,.pdf";
    }
     return "image/png, image/jpeg, image/svg, image/eps";
  };

  const getSupportedFilesLabel = () => {
    if (isDocumentUpload) return "Add: PDF/JPG";
    if (isVideoUpload) return "Add: MP4/PDF";
    return "Add: JPEG, PNG, SVG";
  };

  return (
    <>
      {isLoading ? (
        <Box sx={[styles.contentContainerStyle, customContentContainerStyle]}>
          <Box sx={styles.loaderBox}>
            <CustomLoader customHeight={styles.spinnerStyle} />
            {(uploadPercentage || uploadPercentage === 0) && (
              <Box sx={additionalStyle}>
               {uploadPercentage !==100 && <Typography
                  sx={styles.percentageText}
                >{`${Math.round(uploadPercentage)}%`}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={[styles.contentContainerStyle, customContentContainerStyle]}
          {...webProps}
          onClick={handleUploadClick}
        >
          <CloudUploadIcon sx={styles.uploadIcon} />
          <Typography sx={styles.infoStyle}>
            {getSupportedFilesLabel()}
          </Typography>
          {!!errorMessage && (
            <Typography
              sx={{
                ...styles.error,
                ...commonStyles.errorMessage,
              }}
              fontWeight="600"
            >
              {errorMessage}
            </Typography>
          )}
          <input
            type="file"
            ref={fileInputRef}
            name="fileUpload"
            accept={getAcceptedFiles()}
            onChange={(event) => fileUploadHandler(event)}
            style={styles.hideRawInputField}
          />
        </Box>
      )}
    </>
  );
};

DragAndDropCard.defaultProps = {
  errorMessage: "",
  fileInputRef: null,
  fileUploadHandler: () => {},
  handleDragOver: () => {},
  handleDrop: () => {},
  handleUploadClick: () => {},
  isLoading: false,
  uploadPercentage: 0,
  customContentContainerStyle: {},
};

DragAndDropCard.propTypes = {
  errorMessage: PropTypes.string,
  fileInputRef: PropTypes.any,
  fileUploadHandler: PropTypes.func,
  handleDragOver: PropTypes.func,
  handleDrop: PropTypes.func,
  handleUploadClick: PropTypes.func,
  isDocumentUpload: PropTypes.bool,
  isVideoUpload: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadPercentage: PropTypes.number,
  customContentContainerStyle: PropTypes.object,
  accept: PropTypes.string,
};

export default DragAndDropCard;
