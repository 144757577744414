import { useState } from "react";
import {
  isMobilePhone,
  isValidZip,
  phoneValidation,
  zipValidation,
} from "../utils/validation";

const usePhoneZipValidation = () => {
  const [invalidZipMessage, setInvalidZipMessage] = useState("");
  const [invalidPhoneNumberMessage, setInvalidPhoneNumberMessage] =
    useState("");

  const validate = ({ phoneNumber, country, zip, validationKey }) => {
    if (validationKey === "phone_number") {
      if (phoneNumber) {
        const isPhoneValid = country
          ? phoneValidation(country, phoneNumber)
          : isMobilePhone(phoneNumber);
        if (!isPhoneValid) {
          setPhoneNumberValid(country);
        } else {
          setInvalidPhoneNumberMessage("");
        }
      } else {
        setInvalidPhoneNumberMessage("Please enter a valid phone number");
      }
    } else if (validationKey === "zip") {
      if (zip) {
        const isZipValid = country
          ? zipValidation(country, zip)
          : isValidZip(zip);
        if (!isZipValid) {
          setZipValid(country);
        } else {
          setInvalidZipMessage("");
        }
      } else {
        setInvalidZipMessage("Please enter a valid zip");
      }
    } else if (validationKey === "country") {
      if (phoneNumber) {
        const isPhoneValid = phoneValidation(country, phoneNumber);
        if (!isPhoneValid) {
          setPhoneNumberValid(country);
        } else {
          setInvalidPhoneNumberMessage("");
        }
      }
      if (zip) {
        const isZipValid = zipValidation(country, zip);
        if (!isZipValid) {
          setZipValid(country);
        } else {
          setInvalidZipMessage("");
        }
      }
    }
  };

  const setZipValid = (country) => {
    const message = country
      ? `Please enter a valid zip for ${country}`
      : "Please enter a valid zip";
    setInvalidZipMessage(message);
  };

  const setPhoneNumberValid = (country) => {
    const message = country
      ? `Please enter a valid phone number for ${country}`
      : "Please enter a valid phone number";
    setInvalidPhoneNumberMessage(message);
  };

  return {
    invalidZipMessage,
    invalidPhoneNumberMessage,
    validate,
  };
};

export default usePhoneZipValidation;
