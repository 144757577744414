import {
  ALERT_USER,
  BLACK,
  BORDER_GREY,
  BROWN_HEADING,
  LIGHTER_GREY,
  LIGHT_RED,
  WHITE,
} from "../../consts/colors";

export const styles = {
  container: {
    padding: "26px",
    backgroundColor: WHITE,
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "500px",
    border: `1px solid ${BORDER_GREY}`,
    "@media (max-width: 600px)": {
      padding: "20px",
      width: "100%",
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
  },
  closeButton: {
    padding: "0",
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  closeIcon: {
    fill: BROWN_HEADING,
  },
  errorIcon: {
    width: "48px",
    height: "48px",
  },
  headingAndSubHeadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  errorHeading: {
    color: BLACK,
    fontSize: "20px",
    fontWeight: "600",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  errorSubHeading: {
    color: LIGHTER_GREY,
    fontSize: "14px",
    fontWeight: "500",
    "@media (max-width: 600px)": {
      fontSize: "12px",
    },
  },
  btnContainer: {
    display: "flex",
  },
  buttonStyle: {
    backgroundColor: ALERT_USER,
    minHeight: "50px",
    color: WHITE,
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    padding: "8px 10px",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderRadius: "10px",
    lineHeight: "20px",
    "&:hover": {
      backgroundColor: LIGHT_RED,
    },
    "@media (max-width: 600px)": {
      minHeight: "32px",
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
};
