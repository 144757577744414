import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";

import { styles } from "./SearchableSelectStyle";

const SearchableSelect = ({
  customTextFieldStyles,
  disableClearable,
  isError,
  isLoadingOptions,
  isTextboxGray,
  keyName,
  menuOptions,
  onClearHandler,
  onFocusHandler,
  onKeyPress,
  placeholder,
  searchableSelectDropdownCustomStyles,
  selectedValue,
  setSelectedOption,
  showStartIcon,
  StartIcon,
  startIconStyles,
  keyValue,
  onChange
}) => {
  const [open, setOpen] = useState(false);

  const changeHandler = (value) => {
    if (!value) {
      setSelectedOption && setSelectedOption("");
      onClearHandler && onClearHandler();
      return;
    }
    setSelectedOption && setSelectedOption(value[keyValue]);
  };

  return (
    <Autocomplete
      disablePortal
      {...{ disableClearable }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option[keyValue] === value[keyValue]
      }
      sx={{
        ...styles.autoCompleteStyles,
        ...(isTextboxGray ? styles.autocompleteGrayBox : {}),
        ...searchableSelectDropdownCustomStyles,
      }}
      getOptionLabel={(option) => option[keyName]}
      options={menuOptions}
      loading={isLoadingOptions}
      onChange={(event, value) => changeHandler(value)}
      onFocus={onFocusHandler ? (e) => onFocusHandler(e) : () => {}}
      value={
        menuOptions.find((option) => option[keyValue] === selectedValue) || null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            ...styles.textfieldStyles,
            ...(styles.searchableBox ),
            ...customTextFieldStyles,
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: showStartIcon && (
              <InputAdornment position="start">
                <StartIcon sx={{ ...startIconStyles }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {isLoadingOptions ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    thickness={4}
                    sx={styles.loaderOnInputStyles}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onKeyPress={onKeyPress ? (event) => onKeyPress(event) : () => {}}
        />
      )}
    />
  );
};

SearchableSelect.propTypes = {
  customTextFieldStyles: PropTypes.object,
  disableClearable: PropTypes.bool,
  isError: PropTypes.bool,
  isLoadingOptions: PropTypes.bool,
  isTextboxGray: PropTypes.bool,
  keyName: PropTypes.string,
  menuOptions: PropTypes.array,
  onClearHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.string,
  setSelectedOption: PropTypes.func,
  showStartIcon: PropTypes.bool,
  startIconStyles: PropTypes.object,
};

export default SearchableSelect;
