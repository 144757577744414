import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

import getToken from "../../auth/getToken";
import navigations from "../routeNames";

const PublicRoute = (props) => {
  const tokenCheck = getToken();
  if (tokenCheck) {
    return <Navigate to={navigations.COURSES} />;
  }

  return props.component;
};

PublicRoute.propTypes = {
  component: PropTypes.node,
};

export default PublicRoute;
