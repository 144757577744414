import {
  ALERT_USER,
  APP_PRIMARY_THEME_COLOR,
  APP_SECONDARY_THEME_COLOR,
  APP_THEME_TEN,
  BLACK,
  BRIGHT_GREY,
  DIM_GREY,
  ECLIPSE,
  GREY91,
  GREY95,
  GREY97,
  METALLIC_SILVER,
  RED_RATING,
  SILVER_GREY,
  TEXT_RED,
  WHITE,
} from "../consts/colors";

export const fontFamily = "Plus Jakarta Sans";

export const commonStyles = {
  pageContainer: {
    flex: 1,
  },
  pageWithGreyBg: {
    backgroundColor: GREY95,
  },
  loaderOnInputStyles: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  errorInput: {
    border: `1px solid ${TEXT_RED}`,
  },
  rightGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "inherit",
    backgroundColor: APP_PRIMARY_THEME_COLOR,
    "@media (max-width: 900px)": {
      justifyContent: "start",
    },
  },
  leftGrid: {
    backgroundColor: WHITE,
    position: "relative",
    width: "100%",
    height: "inherit",
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    backgroundColor: APP_THEME_TEN,
    border: "none",
    fontSize: "16px",
    fontWeight: "600",
    color: BLACK,
    padding: "20px 16px",
    height: "44px",
    width: "100%",
    ".MuiInputBase-input": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  customSrollBar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      width: "7px",
      borderRadius: "12px",
      background: SILVER_GREY,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "12px",
      background: METALLIC_SILVER,
    },
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0 14px",
    overflowX: "auto",
  },
  loaderContainer: {
    height: "calc(100vh - 200px)",
  },
  errorContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  heading: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "40px",
  },
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "@media (max-width : 600px)": {
      flexDirection: "column",
      marginTop: "1.5rem",
    },
  },
  whiteButtonStyles: {
    height: "40px",
    width: "137px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  buttonTwoStyles: {
    color: DIM_GREY,
    // border: `1px solid ${GREY78}`,
    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  mobileModalText: {
    "@media (max-width : 600px)": {
      textAlign: "center",
    },
  },
  highlightBox: {
    backgroundColor: WHITE,
    boxShadow: "0px 2px 3px -1.5px rgba(0, 27, 77, 0.05)",
    borderRadius: "1rem",
    padding: "1.5rem",
    "@media (max-width: 600px)": {
      padding: "1rem",
    },
  },
  hideScrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    scrollbarWidth: "none" /* Firefox */,
  },
  errorMessage: {
    color: RED_RATING,
    fontSize: "10px",
    lineHeight: "14px",
  },
  headerCreateStyle:{
    top: "80px",
      zIndex: 1000,
  }
};

export const modalCommonStyles = {
  modal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
    },
  },
  minWidthModal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
      minWidth: "30vw",
      "@media (max-width: 900px)": {
        minWidth: "50vw",
      },
    },
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "40vh",
  },
  loader: {
    color: APP_SECONDARY_THEME_COLOR,
  },
  headerStyle:{
    zIndex: 1000,
    top: "0px",
  }

};

export const commonSelectBoxStyles = {
  selectBoxContainer: {
    width: "100%",
  },
  customSelectBoxStyles: {
    backgroundColor: GREY97,
    border: `1px solid ${BRIGHT_GREY}`,
    color: `${DIM_GREY}`,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    paddingLeft: "1rem",
  },
  customMenu: {
    maxHeight: "200px",
    overFlow: "auto",
  },
  menuOptions: {
    minHeight: "40px",
    fontSize: "14px",
    lineHeight: "22px",
    color: DIM_GREY,
    fontWeight: 500,
    textTransform:"capitalize",
    "&:hover": {
      background: GREY91,
      "&:selected": {
      },
    },
    "&.MuiMenuItem-root.Mui-selected": {
      color: WHITE,
      background: GREY91,

    },

  },
  errorStyles: {
    border: `1px solid ${ALERT_USER}`,
  },
  grayMenuStyles: {
    backgroundColor: GREY97,
  },

};


