import { APP_PRIMARY_THEME_COLOR, GREY_CHATEAU } from "../../consts/colors";

export const styles={
  backButtonContainer: {
    padding: "5px",
    border: `1px solid ${GREY_CHATEAU}`,
    borderRadius:"5px",
    cursor:"pointer",
    width:"max-content"
  },
  backButton: {
    color: APP_PRIMARY_THEME_COLOR,
  },
}