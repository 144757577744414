import PropTypes from "prop-types";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { styles } from "./SingleFilterListStyles";

const SingleFilterList = ({ filter, includeTitle = true, selectedValue, setFilterListVisible }) => {
  return (
    <>
      {includeTitle && (
        <Box sx={styles.filterTitleContainer}>
          <Typography sx={styles.filterTitle}>{filter.title}</Typography>
        </Box>
      )}
      <RadioGroup
        name={filter.title}
        value={selectedValue[filter.filterKey] || ""}
        onChange={(event) => {
          filter.handleOptionChange(filter.filterKey, event.target.value);
          setFilterListVisible(false);
        }}
        sx={styles.listContainer}
      >
        {filter.content.map((contentItem, contentIndex) => (
          <FormControlLabel
            key={contentIndex}
            value={contentItem.value}
            control={<Radio sx={styles.radioBtnStyles} />}
            label={contentItem.label}
          />
        ))}
      </RadioGroup>
    </>
  );
};

SingleFilterList.propTypes = {
  filter: PropTypes.object,
  includeTitle: PropTypes.bool,
  selectedValue: PropTypes.object,
  setFilterListVisible: PropTypes.func,
};

export default SingleFilterList;
