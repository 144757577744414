export const validatePassword = (password,isEmptyAllowed=false) => {
    if(isEmptyAllowed && password.length===0){
    return true;
    }
    const isValidLength =  password.length >= 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password)
    const isValid = isValidLength && hasLowerCase && hasUpperCase && hasNumber; 
    return isValid 
  }
  