import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";

import { styles } from "./Initial";

const ImageOrInitials = ({
  image,
  text,
  customStyles,
  useFirstWordInitial,
  showSquareImage,
}) => {
  return (
    <>
      {image ? (
        <Avatar
          alt="image"
          src={image}
          sx={{
            ...styles.imageDimension,
            ...(showSquareImage ? styles.squareImageStyles : {}),
            ...customStyles,
          }}
        />
      ) : (
        <Avatar
          sx={{
            ...styles.noImageBackground,
            ...styles.imageDimension,
            ...(showSquareImage ? styles.squareImageStyles : {}),
            ...customStyles,
          }}
        >
          {useFirstWordInitial
            ? text?.trim()?.charAt(0).toUpperCase()
            : (text?.trim()?.split(" ")[1] || text?.split(" ")[0] || "")
                .charAt(0)
                .toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

ImageOrInitials.propTypes = {
  showSquareImage: PropTypes.bool,
  image: PropTypes.string,
  text: PropTypes.string,
  useFirstWordInitial: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default ImageOrInitials;
