import { GREY_CHATEAU, APP_PRIMARY_THEME_COLOR } from "../../consts/colors";

export const styles={
checkBoxStyle:{
    span: {
      "&.Mui-checked": {
        color: APP_PRIMARY_THEME_COLOR,
    },
    '&.Mui-disabled': {
      color:GREY_CHATEAU,
    },
    color:APP_PRIMARY_THEME_COLOR,
    },

  },
  labelStyle:{
    fontSize: "14px",
    color: APP_PRIMARY_THEME_COLOR,
    lineHeight:"20px",
    fontWeight:"500"
  }
}