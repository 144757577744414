import validator from "validator";

export const zipValidation = (country, str) => {
  const countryInLowerCase = country.toLowerCase().trim();
  const testString = str.trim();
  if (countryInLowerCase === "canada")
    return validator.isPostalCode(testString, "CA");
  if (countryInLowerCase === "usa" || countryInLowerCase === "united states")
    return validator.isPostalCode(testString, "US");
  if (countryInLowerCase === "australia")
    return validator.isPostalCode(testString, "AU");
  if (countryInLowerCase === "india")
    return validator.isPostalCode(testString, "IN");
  if (countryInLowerCase === "pakistan") {
    const re = /^[0-9]{5}$/;
    return re.test(testString);
  }
  if (countryInLowerCase === "mexico")
    return validator.isPostalCode(testString, "MX");
  if (countryInLowerCase === "sweden")
    return validator.isPostalCode(testString, "SE");
  if (countryInLowerCase === "norway")
    return validator.isPostalCode(testString, "NO");
  if (countryInLowerCase === "saudi" || countryInLowerCase === "saudi arabia")
    return validator.isPostalCode(testString, "SA");
  if (countryInLowerCase === "france")
    return validator.isPostalCode(testString, "FR");
  if (countryInLowerCase === "spain")
    return validator.isPostalCode(testString, "ES");
  if (
    countryInLowerCase === "uae" ||
    countryInLowerCase === "united arab emirates" ||
    countryInLowerCase === "qatar"
  )
    return testString === "00000";
  if (
    countryInLowerCase === "england" ||
    countryInLowerCase === "united kingdom"
  )
    return validator.isPostalCode(testString, "GB");
  return false;
};

export const phoneValidation = (country, str) => {
  let re;
  const countryInLowerCase = country.toLowerCase();
  const testString = str.trim();
  if (countryInLowerCase === "canada")
    return validator.isMobilePhone(str.toString(), "en-CA");
  if (countryInLowerCase === "usa")
    return validator.isMobilePhone(str.toString(), "en-US");
  if (countryInLowerCase === "australia") {
    re = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
    return re.test(testString);
  }
  if (countryInLowerCase === "india")
    return validator.isMobilePhone(str.toString(), "en-IN");
  if (countryInLowerCase === "pakistan")
    return validator.isMobilePhone(str.toString(), "en-PK");
  if (countryInLowerCase === "sweden")
    return validator.isMobilePhone(str.toString(), "sv-SE");
  if (countryInLowerCase === "france")
    return validator.isMobilePhone(str.toString(), "fr-FR");
  if (countryInLowerCase === "mexico")
    return validator.isMobilePhone(str.toString(), "es-MX");
  if (countryInLowerCase === "norway")
    return validator.isMobilePhone(str.toString(), "nb-NO");
  if (countryInLowerCase === "saudi" || countryInLowerCase === "saudi arabia")
    return validator.isMobilePhone(str.toString(), "ar-SA");
  if (countryInLowerCase === "uae")
    return validator.isMobilePhone(str.toString(), "ar-AE");
  if (countryInLowerCase === "spain")
    return validator.isMobilePhone(str.toString(), "es-ES");
  if (countryInLowerCase === "qatar") {
    re = /^(\+\d{1,3})?\d{6,14}$/;
    return re.test(testString);
  }
  if (
    countryInLowerCase === "england" ||
    countryInLowerCase === "united kingdom"
  )
    return validator.isMobilePhone(str.toString(), "en-GB");
  return false;
};

export const isMobilePhone = (enteredPhoneNumber) => {
  const australiaRegex =
    /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
  const qatarRegex = /^(\+\d{1,3})?\d{6,14}$/;
  return (
    validator.isMobilePhone(enteredPhoneNumber.toString(), [
      "en-CA",
      "en-US",
      "en-IN",
      "en-GB",
      "en-PK",
      "sv-SE",
      "es-MX",
      "fr-FR",
      "nb-NO",
      "ar-SA",
      "ar-AE",
      "es-ES",
    ]) ||
    australiaRegex.test(enteredPhoneNumber) ||
    qatarRegex.test(enteredPhoneNumber)
  );
};

export const isValidZip = (enteredZip) => {
  if (!enteredZip) {
    return false;
  }
  return validator.isPostalCode(enteredZip, "any") || enteredZip === "00000";
};

export const checkForValidPhoneNumber = (
  enteredCountry,
  enteredPhoneNumber,
  setInvalidPhoneNumberMessage
) => {
  if (!enteredCountry) {
    return;
  }
  if (!phoneValidation(enteredCountry, enteredPhoneNumber)) {
    setInvalidPhoneNumberMessage(
      `Please enter a valid phone number for ${enteredCountry}`
    );
  } else {
    setInvalidPhoneNumberMessage("");
  }
};
export const checkForValidZip = (
  enteredCountry,
  enteredZIP,
  setInvalidZipMessage
) => {
  if (!enteredCountry) {
    return;
  }
  if (!zipValidation(enteredCountry, enteredZIP)) {
    setInvalidZipMessage(`Please enter a valid zip of ${enteredCountry}`);
  } else {
    setInvalidZipMessage("");
  }
};


export const validateUserState = (state) => {
  if (state?.trim()?.toLowerCase() === "washington") {
    return "Care Pulse is currently not available in Washington State.";
  }
  return "";
};