export const GENERIC_GET_API_FAILED_ERROR_MESSAGE =
  "Something went wrong ! Please try again later";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const loginErrorMessages = {
  WRONG_CREDENTIALS: "login.wrongCredentials",
  NOT_AUTHORIZED: "login.notAuthorized",
};
export const INVALID_CREDENTIALS = "Invalid email id or password.";
export const NOT_AUTHORISED = "This account doesn't have the required access";
export const INVALID_EMAIL = "Entered email doesn't exist";
export const VALID_EMAIL = "We have sent you an email to reset your password.";
export const ARCHIVE_ERROR_MESSAGE =
  "Failed to archive this review at the moment. Please try again later.";
export const UNARCHIVE_ERROR_MESSAGE =
  "Failed to unarchive this review at the moment. Please try again later.";
export const DELETE_RECORD_ERROR_MESSAGE =
  "Failed to delete the selected record at the moment. Please try again later.";
export const UPLOAD_IMAGE_ERROR_MESSAGE =
  "Failed to upload file at the moment.";
export const UPDATE_CREATE_SAAS_ERROR_MESSAGE =
  "Failed to save saas data at the moment. Please try again later.";
export const DELETE_OFFICE_ERROR_MESSAGE =
  "Failed to delete the selected office at the moment. Please try again later.";
export const SESSION_EXPIRED_MESSAGE = "Your session has been expired.";
