import { useState } from "react";
import useAxiosInstance from "../Axios/hooks/useAxiosInstance";
import { config } from "../consts/constants";
import emailValidator from "../utils/emailValidator";

const useValidateEmail = () => {
  const { axiosInstance } = useAxiosInstance();

  const [error, setError] = useState();
  const validateEmail = async (email, currentEmail = "") => {
    try {
      const emailValid = emailValidator(email);
      if (!emailValid) {
        setError("Please enter a valid email");
        return;
      }
      if (email === currentEmail) {
        setError("");
        return;
      }
      const res = await axiosInstance.get(
        `${config.url.API_URL}api/public/auth/check-email`,
        {
          headers: {
            email: email,
          },
        }
      );
      if (res.status === 200) {
        setError("");
      }
    } catch (error) {
      setError("This email already exists");
    }
  };

  return {
    validateEmail,
    inValidEmailMessage: error,
    setInvalidEmail: setError,
  };
};
export default useValidateEmail;
