export const APP_PRIMARY_THEME_COLOR = "#25456E";
export const APP_SECONDARY_THEME_COLOR = "#28558B";
export const DENTIST_PRIMARY_THEME_COLOR = "#F3F4F6";
export const APP_THEME_ONE = "#15307C";
export const APP_THEME_TWO = "#a372a6";
export const APP_THEME_THREE = "#88358c";
export const APP_THEME_FOUR = "#a142a6";
export const APP_THEME_FIVE = "rgba(134, 80, 148, 0.2)";
export const APP_THEME_SIX = "#efcef1";
export const APP_THEME_SEVEN = "#e2b2e5";
export const APP_THEME_EIGHT = "#fcc3ff";
export const APP_THEME_NINE = "#9b479e";
export const APP_THEME_TEN = "white";
export const APP_LIGHTER_PRIMARY_THEME = "#28558B";
export const APP_THEME_ELEVEN = "#d480d9";
export const APP_THEME_TWELVE = "#17375e";
export const APP_THEME_THIRTEEN = "#28558B";

/* Blacks */
export const BLACK = "#000000";
export const FORM_INPUBOX_COLOR = "#2E3D4B";
export const CAROUSEL_BACKGROUND = "#414342";
export const LIGHT_BLACK = "#585656";
export const DARK_BLACK = "#373938";
export const GRADIENT_BLACK = "#49443E";
export const BLACK_TEXT = "#413A33";
export const BLACK_TEXT_BOLD = "#262626";
export const SUBTLE_BLACK = "#3E3C3A";
export const BLACK_RUSSIAN = "#222429";
export const MIDNIGHT_EXPRESS = "#00000040";

/* Blues */
export const SKY_BLUE = "#F0F5F7";
export const CORN_FLOWER_BLUE = "#6495ED";
export const INFO_BLUE = "#23A5DF";
export const PACIFIC_BLUE = "#0288D1";

/* Greens */
export const VALIDATE_PASSWORD = "#6EB983";
export const PASSWORD_MATCHED = "#0B9743";
export const LIGHT_GREEN = "#DFF6E8";
export const VIBRANT_GREEN = "#5FD48E";
export const ACTIVE_GREEN = "#41A96B";
export const LAUREL = "#2C7A75";
export const AZALEA = "#71ccc7";
export const BUDGREEN = "#87b44d";
export const GREEN_TEAL = "#0DB74C";
export const LUMINESCENT_GREEN = "#639f17";
export const OLD_GOLD = "#bdbd34";
export const YELLOWISH_GREEN = "#bdbd34";

/* Greys */
export const BORDER_GREY = "#CCCCCC";
export const LIGHT_GREY = "#65696D";
export const VERY_LIGHTGREY = "#D3D3D3";
export const LIGHT_TEXT = "#999999";
export const BOLD_TEXT = "#595D61";
export const OPACITY_GREY = "#979797";
export const DISABLE_GREY = "#aaaaaa";
export const DISABLE_GREY_LIGHT = "#e5e5e5";
export const BACKGROUND_GREY = "#C2C1C1";
export const DARK_GREY = "#D5D5D5";
export const THIN_GREY = "#D0D0D0";
export const LIGHTER_TEXT = "#7E7B78";
export const SEPERATOR_GREY = "#EBE5DB";
export const METAL_GREY = "#737A7A";
export const SILVER_GREY = "#DADADA";
export const METALLIC_SILVER = "#BFBFBF";
export const LIGHTER_GREY = "#6F6C6A";
export const LINE_GREY = "#585858";
export const BUTTON_GREY = "#E6E6E6";
export const ADD_GREY = "#DBD5C9";
export const CANCEL_BORDER_GREY = "#DBDBDB";
export const DUSTY_GREY = "#E0DBC6";
export const CLASSIC_GREY = "#C4C4C4";
export const TEXT_GREY = "#918F8D";
export const HELPING_GREY = "#75797D";
export const NIGHT_GREY = "#505050";
export const GREY40 = "#666666";
export const GREY78 = "#C7C7C7";
export const GREY85 = "#D9D9D9";
export const GREY88 = "#E0E0E0";
export const GREY91 = "#E8E8E8";
export const GREY95 = "#f2f2f2";
export const GREY96 = "#f4f4f4";
export const GREY97 = "#F7F7F7";
export const GREY98 = "#FAFAFA";
export const GREY99 = "#FCFCFC";
export const BRIGHT_GREY = "#E8EBEA";
export const DIM_GREY = "#696969";
export const AZURE = "#F1F7F8";
export const WHITE_SMOKE = "#F0F0F0";
export const SILVER = "#BFBFBF";
export const DOVE_GREY = "#737373";
export const SPANISH_GREY = "#999AA0";
export const ONVISIA_GREY = "#d5d3d3";
export const EARLY_CROCUS = "#eceaea";
export const LIGHTISH_GREY = "#F1EFEA";
export const TRANSPARENT_GREY = "#F8F8F8";
export const Grey_CHATEAU = "#A1A6AB";
export const VERY_LIGHT_GREY = "#ECECE";
export const LIGHT_GREY_MAGENTA = "#EEE9EF";
export const TWILIGHT_CHARCOL = "#3E3A3B";
export const GREY_CHATEAU = "#A1A6AB";
export const PLATINUM_SILVER = "#CECECE";
export const GREY77 = "#777777";
export const LIGHT_GREY_SHADE = "#F2F4FC";
export const FADED_GREY = "#0000001f";
export const DIMGREY = "#6a6a6a";
export const PAYNEGREY = "#404041";
export const DOLPHIN_GREY = "#7676801F";
export const GREY_SUIT = "#8E8E93";
export const SILVERY_GREY1 = "#ced0d2";
export const ANTI_FLASH_WHITE = "#E7EAED";

/* Browns */
export const ECLIPSE = "#3F3A3B";
export const GONDOLA = "#3E3B3A;";
export const JON = "3E3A3B";
export const BROWN_HEADING = "#555453";

/* Purples */
export const LIGHT_PURPLE = "#8f5e91";
export const LIGHTER_PURPLE = "#bb8abd";
export const PALE_PURPLE = "#fddeff";
export const HOT_PURPLE = "#57275E";
export const PURPLE_HAZE = "#9e789a";
export const PINKISH_PURPLE = "#FCEBFC";

/* Reds */
export const ALERT_USER = "#EE3C3C";
export const RED_RATING = "#E2582D";
export const TEXT_RED = "#E3582D";
export const CHERRY_RED = "#D2042D";
export const BERN_RED = "#DA3C08";
export const BRIGHT_RED = "#FB3232";
export const LIGHT_RED = "#EB6A6A";
export const VENETION_RED = "#FA4545";
export const MISTY_ROSE = "#FEE6E6";
export const BIZZARE_RED = "#f2dede";
export const APPLE_BLOSSOM_RED = "#a94442";

/* Whites */
export const WHITE = "#ffffff";
export const ROOT_BACKGROUND = "#E5E5E5";
export const DUSTY_WHITE = "#F6F5F1";
export const DIRT_FUSION = "#EEE9E1";

/* Yellows */
export const LIGHT_YELLOW = "#ffdfa3";
export const WHEATISH_YELLOW = "#FEF4E2";
export const OPAQUE_YELLOW = "#FEF4E279";
export const OASIS = "#FFEAC3";
export const YELLOW_SEA = "#f4bf7d";
export const WARNING_YELLOW = "#F1C534";
export const COLORATE = "#D1CC3D";

/* VIOLET */
export const CRAYOLA = "#91288f";

/* Oranges */
export const ORANGE_PEEL = "#f59d40";
export const WARM_ORANGE = "#ea6225";
export const DEEP_ORANGE = "#ff9200";
export const DARK_ORANGE = "#ff9200";

